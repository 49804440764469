import React, { useCallback, useEffect, useState } from 'react';
import {
  DefaultButton,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  Label,
  StackItem,
  TextField,
  IComboBoxOption,
  Checkbox,
  Selection,
  ShimmeredDetailsList,
  CommandBar,
  DetailsListLayoutMode,
  SelectionMode,
  Icon,
} from '@fluentui/react';
import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import _, { create, update } from 'lodash';
import {
  convertConstructionSitesToComboBoxOptions,
  GET_CONSTRUCTION_SITES,
  iconProps,
  IConstructionSite,
  modalContentStyles,
} from '../../../utils';
import { ComboboxWithFilter } from '../../../components/parts';
import DateView from '../../../components/parts/DateView';
import { Accordion, AccordionItem } from '../../../components/parts/Accordion';
import { commandBarTheme } from '../../../theme';
import {
  GET_CUSTOMER_INVOICE_LINE_ITEM_BY_ID,
  ICustomerInvoiceLineItem,
  ICustomerInvoiceLineItemCreateWithoutCustomerInvoice,
  ICustomerInvoiceLineItemUpdateWithoutCustomerInvoice,
} from '../../../utils/CustomerInvoiceLineItem';
import {
  ADD_CUSTOMER_INVOICE_PAYMENT,
  GET_CUSTOMER_INVOICE_PAYMENT_BY_ID,
  ICustomerInvoicePayment,
  UPDATE_CUSTOMER_INVOICE_PAYMENT,
} from '../../../utils/CustomerInvoicePayment';
import {
 ADD_CUSTOMER_INVOICE, GET_CUSTOMER_INVOICE_BY_ID, ICustomerInvoiceCreate, UPDATE_CUSTOMER_INVOICE,
} from '../../../utils/CustomerInvoice';
import useDebounce from '../../../components/hooks/useDebounce';
import {
 convertLeadsToComboBoxOptions, GET_LEADS, GET_LEAD_BY_ID, ILead,
} from '../../../utils/Lead';
import { GET_CUSTOMER_CONTRACT_UPDATES, ICustomerContractUpdate } from '../../../utils/CustomerContractUpdate';
import InvoiceLineDetail from './InvoiceLineDetail';
import PaymentDetail from './PaymentDetail';

type Props = {
  isOpen: boolean;
  dismissPanel: () => void;
  customerInvoiceSource: ICustomerInvoiceCreate;
  // constructionSite?: IConstructionSite | IConstructionSiteCreateUpdate;
}

const CustomerInvoiceDetail = ({
  isOpen,
  dismissPanel,
  // saveCustomerInvoice,
  customerInvoiceSource = {},
  // setCustomerInvoice,
  // refetchCustomerInvoice,
}: Props) => {
  const [invoiceAmount, setInvoiceAmount] = useState(0);
  const [invoiceAmountPayed, setInvoiceAmountPayed] = useState(0);
  const [customerFilter, setCustomerFilter] = useState('');
  const [constructionSiteFilter, setConstructionSiteFilter] = useState('');

  const [customerInvoice, setCustomerInvoice] = useState<ICustomerInvoiceCreate>(customerInvoiceSource);

  useQuery(
    GET_CUSTOMER_INVOICE_BY_ID,
    {
      variables: customerInvoiceSource
        ? { where: { id: customerInvoiceSource.id } }
        : undefined,
      skip: !customerInvoiceSource || !customerInvoiceSource.id,
      onCompleted: data => {
        if (data && data.findOneCustomerInvoice) setCustomerInvoice(data.findOneCustomerInvoice);
      },
    },
  );

  const debouncedCustomerFilter = useDebounce(customerFilter, 500);
  const debouncedFilter = useDebounce(constructionSiteFilter, 500);

  const { data: constructionSitesData } = useQuery(GET_CONSTRUCTION_SITES, {
    // fetchPolicy: 'no-cache',
    variables: {
      take: 20,
      filter: {
        OR: [
          {
            id: customerInvoice &&
              customerInvoice.construction_site && {
                in: [customerInvoice?.construction_site?.id],
              },
          },
          {
            name: {
              contains: debouncedFilter,
            },
          },
        ],
      },
    },
  });

  const [addCustomerInvoice] = useMutation(ADD_CUSTOMER_INVOICE);
  const [modifyCustomerInvoice] = useMutation(UPDATE_CUSTOMER_INVOICE);

  const saveCustomerInvoice = () => {
    if (customerInvoice) {
      const connectCustomerInvoiceUpdates: {id?: number}[] = [];
      const disconnectCustomerInvoiceUpdates: {id?: number}[] = [];
      const createInvoiceLineItems: ICustomerInvoiceLineItemCreateWithoutCustomerInvoice[] = [];
      const deleteInvoiceLineItems: {id?: number}[] = [];
      const updateInvoiceLineItems: {where: {id?: number}, data: ICustomerInvoiceLineItemUpdateWithoutCustomerInvoice}[] = [];

      if (customerInvoice && customerInvoice.customer_contract_updates) {
        for (let i = 0; i < customerInvoice.customer_contract_updates.length; i++) {
          if (customerInvoice.customer_contract_updates[i].connect) {
            connectCustomerInvoiceUpdates.push({
              id: customerInvoice.customer_contract_updates[i].id,
            });
          } else if (customerInvoice.customer_contract_updates[i].disconnect) {
            disconnectCustomerInvoiceUpdates.push({
              id: customerInvoice.customer_contract_updates[i].id,
            });
          }
        }
      }

      if (customerInvoice && customerInvoice.customer_invoice_line_items) {
        for (let i = 0; i < customerInvoice.customer_invoice_line_items.length; i++) {
            const item = customerInvoice.customer_invoice_line_items[i];

            if (item && item.isNew && !item.delete) {
              // add new line item
              createInvoiceLineItems.push({
                comments: item.comments,
                description: item.description || '',
                percentage_vat: item.percentage_vat && +item.percentage_vat,
                show_only_total: item.show_only_total,
                units: item.units && +item.units,
                unit_price: item.unit_price && +item.unit_price,
                // connect customer_contract_update_line_item for later reference & deletion
                customer_contract_update_line_item: item.customer_contract_update_line_item && {
                  connect: {
                    id: item.customer_contract_update_line_item.id!,
                  },
                },
              });
            } else if (item && item.delete && !item.isNew && item.isDirty) {
              // delete line item
              console.log(item);
              deleteInvoiceLineItems.push({ id: item.id });
            } else if (item.isDirty && !item.isNew) {
              // update lin eitem
              updateInvoiceLineItems.push({
                where: { id: item.id },
                data: {
                  comments: item.comments,
                  description: item.description || '',
                  percentage_vat: item.percentage_vat && +item.percentage_vat,
                  show_only_total: item.show_only_total,
                  units: item.units && +item.units,
                  unit_price: item.unit_price && +item.unit_price,
                },
              });
            }
        }
      }

      const allInput: any = {
        invoice_due_date: customerInvoice.invoice_due_date,
        invoice_paid: customerInvoice.invoice_paid,
        sent: customerInvoice.sent,
        credit_note: customerInvoice.credit_note,
        invoice_date: customerInvoice.invoice_date,
        intro: customerInvoice.intro,
        outro: customerInvoice.outro,
        construction_site: customerInvoice.construction_site && customerInvoice.construction_site.id ? {
          connect: { id: customerInvoice.construction_site.id },
        } : undefined,
        lead: customerInvoice.lead && customerInvoice.lead.id ? {
          connect: { id: customerInvoice.lead.id },
        } : undefined,
        customer_invoice_line_items: {
          create: createInvoiceLineItems,
        },
        customer_contract_updates: {
          connect: connectCustomerInvoiceUpdates,
          // disconnect: disconnectCustomerInvoiceUpdates,
        },
      };

      if (customerInvoice.id) {
        allInput.customer_contract_updates = {
          connect: connectCustomerInvoiceUpdates,
          disconnect: disconnectCustomerInvoiceUpdates,
        };

        allInput.customer_invoice_line_items = {
          create: createInvoiceLineItems,
          delete: deleteInvoiceLineItems,
          update: updateInvoiceLineItems,
        };

        modifyCustomerInvoice({
          variables: {
            id: customerInvoice.id,
            data: allInput,
          },
        }).then((x) => {
          setConstructionSiteFilter('');
          setCustomerFilter('');
          // setCustomerInvoice(x.data.createCustomerInvoice);
          dismissPanel();
        });
      } else {
        addCustomerInvoice({
          variables: {
            data: allInput,
          },
        }).then(x => {
          setConstructionSiteFilter('');
          setCustomerFilter('');
          setCustomerInvoice(x.data.createCustomerInvoice);
          // dismissPanel();
        });
      }
    }
  };

  const getLeadFilter = useCallback(() => {
    const filter: any = {
      AND: [],
    };
    if (debouncedCustomerFilter) {
      const stringArray = debouncedCustomerFilter.split(' ');
      for (let i = 0; i < stringArray.length; i++) {
        filter.AND = [
          ...filter.AND,
          {
            OR: [
              {
                first_name1: {
                  contains: stringArray[i],
                },
              },
              {
                first_name2: {
                  contains: stringArray[i],
                },
              },
              {
                last_name1: {
                  contains: stringArray[i],
                },
              },
              {
                last_name2: {
                  contains: stringArray[i],
                },
              },
            ],
          },
        ];
      }
    }

    return filter;
  }, [debouncedCustomerFilter]);

  const { data: customersData } = useQuery(GET_LEADS, {
    // fetchPolicy: 'no-cache',
    variables: {
      take: debouncedCustomerFilter.length > 0 ? undefined : 10,
      filter: getLeadFilter(),
    },
  });

  const { data: customerData } = useQuery(GET_LEAD_BY_ID, {
    // fetchPolicy: 'no-cache',
    variables:
      customerInvoice && customerInvoice.lead
        ? { where: { id: customerInvoice.lead.id } }
        : undefined,
    skip: !customerInvoice || !customerInvoice.lead,
  });

  const { data: customerContractUpdatesData } = useQuery(
    GET_CUSTOMER_CONTRACT_UPDATES,
    {
      variables: customerInvoice && customerInvoice.construction_site && customerInvoice.construction_site.id
        ? { filter: { construction_site: { id: { equals: customerInvoice.construction_site.id } } } }
        : undefined,
      skip: !customerInvoiceSource || !customerInvoice.id || !customerInvoice.construction_site || !customerInvoice.construction_site.id,
      onCompleted: data => {
        console.log(data);
        // setCustomerInvoice(data.findOneCustomerInvoice);
      },
    },
  );

  useEffect(() => {
    console.log(customerContractUpdatesData);
  }, [customerContractUpdatesData]);

  useEffect(() => {
    if (customerInvoice) {
      if (customerInvoice.customer_invoice_line_items) {
        setInvoiceAmount(
          customerInvoice.customer_invoice_line_items.filter(item => !item.delete)
            .reduce(
              (previousValue: any, currentValue: any) =>
                previousValue +
                (!currentValue.percentage_vat ||
                +currentValue.percentage_vat === 0
                  ? currentValue.unit_price * currentValue.units
                  : +currentValue.percentage_vat < 1
                  ? currentValue.unit_price *
                    currentValue.units *
                    (1 + currentValue.percentage_vat)
                  : currentValue.unit_price *
                    currentValue.units *
                    (1 + currentValue.percentage_vat / 100)),
              0,
            )
            .toFixed(2),
        );
      }
      if (customerInvoice.customer_invoice_payments) {
        setInvoiceAmountPayed(
          customerInvoice.customer_invoice_payments
            .reduce(
              (previousValue: any, currentValue: any) =>
                previousValue + currentValue.amount,
              0,
            )
            .toFixed(2),
        );
      }
    }
  }, [customerInvoice]);

  const onChangeTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    setCustomerInvoice((prevState: any) => ({
      ...prevState,
      [(event.target as HTMLTextAreaElement).name]: newValue || '',
    }));
  };

  const [selectedValueItem, setSelectedValueItem] = useState<
    number | string | undefined
  >();

  // Invoice line
  const [isInvoiceLinePanelOpen, setIsInvoiceLinePanelOpen] = useState(false);
  const [invoiceLine, setInvoiceLine] = useState<ICustomerInvoiceLineItem | undefined>({});

  const getSelectionInvoiceLineDetails = () => {
    const currentInvoiceLineSelection: any =
      selectionInvoiceLine.getSelection();

    if (currentInvoiceLineSelection.length > 0) {
      setInvoiceLine(currentInvoiceLineSelection[0]);
    } else {
      setInvoiceLine(undefined);
    }
  };

  const selectionInvoiceLine = new Selection({
    onSelectionChanged: getSelectionInvoiceLineDetails,
  });
/*
  useQuery(GET_CUSTOMER_INVOICE_LINE_ITEM_BY_ID, {
    // notifyOnNetworkStatusChange: true,
    variables: invoiceLine
      ? { where: { id: invoiceLine.id } }
      : undefined,
    skip: !invoiceLine,
    onCompleted: data => {
      setInvoiceLine(data.findOneCustomerInvoiceLineItem);
    },
  });
*/

  const openInvoiceLineDetail = (newInvoiceLine?: boolean) => {
    if (newInvoiceLine) {
      setInvoiceLine({
        isNew: true,
      });
    }
    setIsInvoiceLinePanelOpen(true);
  };

  // const [addInvoiceLine] = useMutation(ADD_CUSTOMER_INVOICE_LINE_ITEM);
  // const [modifyInvoiceLine] = useMutation(UPDATE_CUSTOMER_INVOICE_LINE_ITEM);

  const saveInvoiceLine = () => {
    const currentCustomerInvoice = _.cloneDeep(customerInvoice);
    if (invoiceLine) {
      if (invoiceLine && invoiceLine.id && currentCustomerInvoice.customer_invoice_line_items) {
        // get invoice line index
        const index = currentCustomerInvoice.customer_invoice_line_items.findIndex(item => item.id === invoiceLine.id);
        currentCustomerInvoice.customer_invoice_line_items[index] = invoiceLine;
      } else {
        // add to object
        const newLineItem = _.cloneDeep(invoiceLine);
        if (!newLineItem.id) newLineItem.id = moment().unix();
        if (currentCustomerInvoice.customer_invoice_line_items) currentCustomerInvoice.customer_invoice_line_items.push(newLineItem);
        else currentCustomerInvoice.customer_invoice_line_items = [newLineItem];
      }
    }

    setCustomerInvoice(currentCustomerInvoice);

    setIsInvoiceLinePanelOpen(false);
  };
/*
  const saveInvoiceLine = () => {
    if (invoiceLine) {
      const allInput = {
        comments: invoiceLine.comments,
        description: invoiceLine.description,
        percentage_vat: invoiceLine.percentage_vat && +invoiceLine.percentage_vat,
        show_only_total: invoiceLine.show_only_total,
        units: invoiceLine.units && +invoiceLine.units,
        unit_price: invoiceLine.unit_price && +invoiceLine.unit_price,
        customer_invoice: {
          connect: { id: customerInvoice.id },
        },
      };

      if (invoiceLine.id) {
        modifyInvoiceLine({
          variables: {
            id: invoiceLine.id,
            data: allInput,
          },
        }).then(() => {
          // refetchCustomerInvoice();
          setIsInvoiceLinePanelOpen(false);
        });
      } else {
        addInvoiceLine({
          variables: {
            data: allInput,
          },
        }).then(() => {
          // refetchCustomerInvoice();
          setIsInvoiceLinePanelOpen(false);
        });
      }
    }
  };
*/
  // Payment
  const [isPaymentPanelOpen, setIsPaymentPanelOpen] = useState(false);
  const [payment, setPayment] = useState<any>();
  const [selectionPaymentDetails, setSelectionPaymentDetails] = useState<
    ICustomerInvoicePayment | undefined
  >();

  const getSelectionPaymentDetails = () => {
    const currentPaymentSelection: any = selectionPayment.getSelection();

    if (currentPaymentSelection.length > 0) {
      setSelectionPaymentDetails(currentPaymentSelection[0]);
    } else {
      setSelectionPaymentDetails(undefined);
    }
  };

  const selectionPayment = new Selection({
    onSelectionChanged: getSelectionPaymentDetails,
  });

  useQuery(GET_CUSTOMER_INVOICE_PAYMENT_BY_ID, {
    variables: selectionPaymentDetails
      ? { where: { id: selectionPaymentDetails.id } }
      : undefined,
    skip: !selectionPaymentDetails,
    onCompleted: data => {
      setPayment(data.findOneCustomerInvoicePayment);
    },
  });

  const openPaymentDetail = (newPayment?: boolean) => {
    if (newPayment) {
      setPayment(undefined);
    }
    setIsPaymentPanelOpen(true);
  };

  const [addPayment] = useMutation(ADD_CUSTOMER_INVOICE_PAYMENT);
  const [modifyPayment] = useMutation(UPDATE_CUSTOMER_INVOICE_PAYMENT);

  const savePayment = () => {
    if (payment) {
      const allInput = {
        comments: payment.comments,
        amount: +payment.amount,
        payment_date: payment.payment_date,
        customer_invoice: {
          connect: { id: customerInvoice.id },
        },
      };

      if (payment.id) {
        modifyPayment({
          variables: {
            id: payment.id,
            data: allInput,
          },
        }).then(() => {
          // refetchCustomerInvoice();
          setIsPaymentPanelOpen(false);
        });
      } else {
        addPayment({
          variables: {
            data: allInput,
          },
        }).then(() => {
          // refetchCustomerInvoice();
          setIsPaymentPanelOpen(false);
        });
      }
    }
  };

  // Payment
  // const [isPaymentPanelOpen, setIsPaymentPanelOpen] = useState(false);
  // const [payment, setPayment] = useState<ICustomerInvoicePayment | undefined>();

  const getConstructionSiteComboBoxOptions = useCallback(() => {
    let constructionSiteArray: IConstructionSite[] = [];

    if (constructionSitesData && constructionSitesData.findManyConstructionSites) {
      constructionSiteArray = constructionSiteArray.concat(constructionSitesData.findManyConstructionSites);
    }
    if (customerInvoice && customerInvoice.construction_site && customerInvoice.construction_site.id) {
      let constructionSiteIncluded = false;
      if (constructionSitesData && constructionSitesData.findManyConstructionSites) {
        for (let i = 0; i < constructionSitesData.findManyConstructionSites.length; i++) {
          if (constructionSitesData.findManyConstructionSites[i].id === customerInvoice.construction_site.id) {
            constructionSiteIncluded = true;
          }
        }
      }

      if (!constructionSiteIncluded) {
        constructionSiteArray = constructionSiteArray.concat(customerInvoice.construction_site as IConstructionSite);
      }
    }

    return convertConstructionSitesToComboBoxOptions(constructionSiteArray);
  }, [customerInvoice, constructionSitesData]);

  const getCustomerComboBoxOptions = useCallback(() => {
    let customerArray: ILead[] = [];

    if (customersData && customersData.findManyLeads) {
      customerArray = customerArray.concat(customersData.findManyLeads);
    }

    if (customerInvoice && customerInvoice.lead && customerInvoice.lead.id) {
      let customerIncluded = false;
      if (customersData && customersData.findManyLeads) {
        for (let i = 0; i < customersData && customersData.findManyLeads.length; i++) {
          if (customersData && customersData.findManyLeads[i].id === customerInvoice.lead.id) {
            customerIncluded = true;
          }
        }
      }

      if (!customerIncluded) {
        customerArray = customerArray.concat(customerInvoice.lead as ILead);
      }
    }

    return convertLeadsToComboBoxOptions(customerArray);
  }, [customerInvoice, customersData]);

  const addCustomerContractUpdate = (item: ICustomerContractUpdate) => {
    setCustomerInvoice((prevState) => {
      const currentCustomerInvoice = _.cloneDeep(prevState);
      const currentUpdates = _.cloneDeep(prevState.customer_contract_updates);
      if (currentUpdates && currentUpdates.length > 0) {
        let exists = false;
        for (let i = 0; i < currentUpdates.length; i++) {
          if (currentUpdates[i].id === item.id) {
            exists = true;
            currentUpdates[i].connect = true;
            currentUpdates[i].disconnect = false;

            currentCustomerInvoice.customer_contract_updates = currentUpdates;
          }
        }
        if (!exists) {
          currentCustomerInvoice.customer_contract_updates = [
            ...currentUpdates,
            {
              ...item,
              connect: true,
              disconnect: false,
            },
          ];
        }
      } else {
        currentCustomerInvoice.customer_contract_updates = [
          {
            ...item,
            connect: true,
            disconnect: false,
          },
        ];
      }

      if (currentCustomerInvoice && !currentCustomerInvoice.customer_invoice_line_items) {
        currentCustomerInvoice.customer_invoice_line_items = [];
      }

      if (item && item.customer_contract_update_line_items && currentCustomerInvoice.customer_invoice_line_items) {
        for (let i = 0; i < item.customer_contract_update_line_items.length; i++) {
          const contractUpdateLine = item.customer_contract_update_line_items[i];
          const currentIndex = currentCustomerInvoice.customer_invoice_line_items.findIndex(invoiceLineItem => invoiceLineItem.customer_contract_update_line_item && invoiceLineItem.customer_contract_update_line_item.id === contractUpdateLine.id);

          if (currentIndex === -1) {
            // otherwise it already exists on invoice
            currentCustomerInvoice.customer_invoice_line_items.push({
              units: contractUpdateLine.units,
              unit_price: contractUpdateLine.unit_price,
              description: `meerwerk ${item.document_no}: ${contractUpdateLine.description}`,
              percentage_vat: contractUpdateLine.percentage_vat,
              show_only_total: false,
              isDirty: true,
              isNew: true,
              id: moment().unix(),
              customer_contract_update_line_item: {
                id: contractUpdateLine.id,
              },
            });
          }
        }
      }

      return currentCustomerInvoice;
    });
  };

  const removeCustomerContractUpdate = (item: ICustomerContractUpdate) => {
    setCustomerInvoice((prevState) => {
      const currentCustomerInvoice = _.cloneDeep(prevState);
      const currentUpdates = _.cloneDeep(prevState.customer_contract_updates);

      if (currentUpdates && currentUpdates.length > 0) {
        let exists = false;
        for (let i = 0; i < currentUpdates.length; i++) {
          if (currentUpdates[i].id === item.id) {
            exists = true;
            currentUpdates[i].connect = false;
            currentUpdates[i].disconnect = true;

            currentCustomerInvoice.customer_contract_updates = currentUpdates;
          }
        }
      }

      if (item && item.customer_contract_update_line_items && currentCustomerInvoice.customer_invoice_line_items) {
        for (let i = 0; i < item.customer_contract_update_line_items.length; i++) {
          const contractUpdateLine = item.customer_contract_update_line_items[i];
          const currentIndex = currentCustomerInvoice.customer_invoice_line_items.findIndex(invoiceLineItem => invoiceLineItem.customer_contract_update_line_item && invoiceLineItem.customer_contract_update_line_item.id === contractUpdateLine.id);

          if (currentIndex !== -1) {
            // otherwise it already exists on invoice
            currentCustomerInvoice.customer_invoice_line_items[currentIndex].isDirty = true;
            currentCustomerInvoice.customer_invoice_line_items[currentIndex].delete = true;
          }
        }
      }

      return currentCustomerInvoice;
    });
  };

  return (
    <Panel
      isLightDismiss
      isOpen={isOpen}
      onDismiss={dismissPanel}
      closeButtonAriaLabel='Close'
      headerText={
        customerInvoice && customerInvoice.id
          ? 'Uitgaande factuur wijzigen'
          : 'Uitgaande factuur toevoegen'
      }
      type={PanelType.custom}
      customWidth='1050px'
    >
      <InvoiceLineDetail
        isOpen={isInvoiceLinePanelOpen}
        dismissPanel={() => {
          setIsInvoiceLinePanelOpen(false);
        }}
        invoiceLine={invoiceLine}
        saveInvoiceLine={saveInvoiceLine}
        setInvoiceLine={setInvoiceLine}
      />

      <PaymentDetail
        isOpen={isPaymentPanelOpen}
        dismissPanel={() => {
          setIsPaymentPanelOpen(false);
        }}
        payment={payment}
        savePayment={savePayment}
        setPayment={setPayment}
      />

      <div>
        <div className={modalContentStyles.header} />
        <div className={modalContentStyles.body}>
          {customerInvoice && <Label>ID: {customerInvoice.id}</Label>}

          <Stack
            style={{ flexDirection: 'row', justifyContent: 'space-between' }}
          >
            <StackItem style={{ width: '48%' }}>
              <Stack
                style={{
                  marginBottom: 10,
                }}
              >
                <ComboboxWithFilter
                  label='Werf'
                  options={getConstructionSiteComboBoxOptions()}
                  value={
                    customerInvoice && customerInvoice.construction_site
                      ? customerInvoice.construction_site.id
                      : ''
                  }
                  multiline={false}
                  callBack={(newValue: IComboBoxOption[]) => {
                    if (newValue && newValue.length > 0) {
                      const result = { ...customerInvoice };

                      result.construction_site = {
                        id: newValue[0].key as number,
                      };

                      const constructionSites = constructionSitesData && constructionSitesData.findManyConstructionSites;

                      if (constructionSites && constructionSites.length > 0) {
                        for (let i = 0; i < constructionSites.length; i++) {
                          if (
                            constructionSites[i].id ===
                            (newValue[0].key as number)
                          ) {
                            result.construction_site = constructionSites[i];

                            if (constructionSites[i] && constructionSites[i].lead && !result.lead) {
                              result.lead = constructionSites[i].lead;
                            }
                          }
                        }
                      }

                      setCustomerInvoice(result);
                    }
                  }}
                  allowFreeForm
                  setFilter={(value: string) => {
                    setConstructionSiteFilter(value);
                  }}
                />
              </Stack>
              <Stack
                style={{
                  marginBottom: 10,
                }}
              >
                <ComboboxWithFilter
                  label='Klant'
                  options={getCustomerComboBoxOptions()}
                  value={
                    customerInvoice && customerInvoice.lead
                      ? customerInvoice.lead.id
                      : ''
                  }
                  multiline={false}
                  callBack={(newValue: IComboBoxOption[]) => {
                    if (newValue && newValue.length > 0) {
                      const result = { ...customerInvoice };

                      result.lead = {
                        id: newValue[0].key as number,
                      };

                      setCustomerInvoice(result);
                    }
                  }}
                  allowFreeForm
                  setFilter={(value: string) => {
                    setCustomerFilter(value);
                  }}
                />
              </Stack>
              <Stack style={{ marginBottom: 10, marginTop: 31 }}>
                <Label>Factuurnummer: {customerInvoice && customerInvoice.invoice_no}</Label>
              </Stack>
              <Stack style={{ marginBottom: 10 }}>
                <DateView
                  item={customerInvoice}
                  setItem={setCustomerInvoice}
                  date={customerInvoice && customerInvoice.invoice_date}
                  label='Factuurdatum'
                  field='invoice_date'
                />
              </Stack>
              <Stack style={{ marginBottom: 10, marginTop: 20 }}>
                <Checkbox
                  name='credit_note'
                  label='Creditnota?'
                  checked={
                    !!(customerInvoice && customerInvoice.credit_note === true)
                  }
                  onChange={(e, checked) => {
                    setCustomerInvoice((prevState: any) => ({
                      ...prevState,
                      credit_note: checked,
                    }));
                  }}
                />
              </Stack>
              <Stack style={{ marginBottom: 10, marginTop: 20 }}>
                <Checkbox
                  name='invoice_paid'
                  label='Betaald?'
                  checked={
                    !!(customerInvoice && customerInvoice.invoice_paid === true)
                  }
                  onChange={(e, checked) => {
                    setCustomerInvoice((prevState: any) => ({
                      ...prevState,
                      invoice_paid: checked,
                    }));
                  }}
                />
              </Stack>
              <Stack style={{ marginBottom: 10, marginTop: 20 }}>
                <Checkbox
                  name='sent'
                  label='Verzonden?'
                  checked={
                    !!(customerInvoice && customerInvoice.sent === true)
                  }
                  defaultChecked={
                    !!(customerInvoice && customerInvoice.sent === true)
                  }
                  onChange={(e, checked) => {
                    setCustomerInvoice((prevState: any) => ({
                      ...prevState,
                      sent: checked,
                    }));
                  }}
                />
              </Stack>
            </StackItem>
            <StackItem style={{ width: '48%' }}>
              <Stack style={{ marginBottom: 10 }}>
                <TextField
                  name='intro'
                  label='Inleidende tekst'
                  multiline
                  value={
                    customerInvoice && customerInvoice.intro
                      ? customerInvoice.intro
                      : ''
                  }
                  onChange={onChangeTextFieldValue}
                />
              </Stack>
              <Stack style={{ marginBottom: 10 }}>
                <TextField
                  name='outro'
                  label='Afsluitende tekst'
                  multiline
                  value={
                    customerInvoice && customerInvoice.outro
                      ? customerInvoice.outro
                      : ''
                  }
                  onChange={onChangeTextFieldValue}
                />
              </Stack>

              <Stack style={{ marginBottom: 10 }}>
                <DateView
                  item={customerInvoice}
                  setItem={setCustomerInvoice}
                  date={customerInvoice && customerInvoice.invoice_due_date}
                  label='Betaalbaar voor'
                  field='invoice_due_date'
                />
              </Stack>

              <Stack style={{ marginBottom: 10 }}>
                <Label>Totaal incl. BTW</Label>
                &euro; {invoiceAmount || '0.00'}
              </Stack>

              <Stack style={{ marginBottom: 10 }}>
                <Label>Openstaand bedrag</Label>
                &euro; {(invoiceAmount - invoiceAmountPayed).toFixed(2)}
              </Stack>
            </StackItem>
          </Stack>

          {customerInvoice && customerInvoice.id ? (
            <Stack style={{ marginBottom: 10, marginTop: 30 }}>
              <h3>Details</h3>
              <CommandBar
                items={[
                        {
                          key: 'new',
                          text: 'Nieuw',
                          iconProps: { iconName: 'Add' },
                          onClick: () => openInvoiceLineDetail(true),
                          theme: commandBarTheme,
                        },
                        {
                          key: 'modify',
                          text: 'Wijzig',
                          iconProps: { iconName: 'Edit' },
                          onClick: () => openInvoiceLineDetail(),
                          theme: commandBarTheme,
                          disabled: !invoiceLine || !invoiceLine.id,
                        },
                      ]}
                theme={commandBarTheme}
              />
              <ShimmeredDetailsList
                items={customerInvoice.customer_invoice_line_items || []}
                columns={[
                        {
                          key: 'column1',
                          name: 'Omschrijving',
                          fieldName: 'description',
                          minWidth: 175,
                          maxWidth: 175,
                          isRowHeader: true,
                          onRender: (line_item: any) => (
                            <span>
                              <span style={{ opacity: line_item.delete ? '0.5' : undefined }}>{line_item.description}</span> {line_item.delete && '(verwijderen)'}
                            </span>
                          ),
                          data: 'string',
                          isPadded: true,
                        },
                        {
                          key: 'column2',
                          name: 'Aantal',
                          fieldName: 'units',
                          minWidth: 40,
                          maxWidth: 40,
                          isRowHeader: true,
                          onRender: (line_item: any) => (
                            <span style={{ opacity: line_item.delete ? '0.5' : undefined }}>{line_item.units}</span>
                          ),
                          data: 'string',
                          isPadded: true,
                        },
                        {
                          key: 'column3',
                          name: 'Eenheidsprijs',
                          fieldName: 'unit_price',
                          minWidth: 70,
                          maxWidth: 70,
                          isRowHeader: true,
                          onRender: (line_item: any) => (
                            <span style={{ opacity: line_item.delete ? '0.5' : undefined }}>
                              &euro; {line_item.unit_price && line_item.unit_price.toFixed(2)}
                            </span>
                          ),
                          data: 'string',
                          isPadded: true,
                        },
                        {
                          key: 'column4',
                          name: 'BTW %',
                          fieldName: 'percentage_vat',
                          minWidth: 30,
                          maxWidth: 30,
                          isRowHeader: true,
                          onRender: (line_item: any) => (
                            <span style={{ opacity: line_item.delete ? '0.5' : undefined }}>
                              {line_item.percentage_vat > 1
                                ? line_item.percentage_vat
                                : line_item.percentage_vat * 100}
                              %
                            </span>
                          ),
                          data: 'string',
                          isPadded: true,
                        },
                        {
                          key: 'column5',
                          name: 'Totaal excl. BTW',
                          fieldName: 'excl_vat',
                          minWidth: 80,
                          maxWidth: 80,
                          isRowHeader: true,
                          onRender: (line_item: any) => (
                            <span style={{ opacity: line_item.delete ? '0.5' : undefined }}>
                              &euro;{' '}
                              {(line_item.unit_price * line_item.units).toFixed(
                                2,
                              )}
                            </span>
                          ),
                          data: 'string',
                          isPadded: true,
                        },
                        {
                          key: 'column6',
                          name: 'Totaal incl. BTW',
                          fieldName: 'incl_vat',
                          minWidth: 80,
                          maxWidth: 80,
                          isRowHeader: true,
                          onRender: (line_item: any) => (
                            <span style={{ opacity: line_item.delete ? '0.5' : undefined }}>
                              &euro;{' '}
                              {!line_item.percentage_vat ||
                              +line_item.percentage_vat === 0
                                ? (
                                    line_item.unit_price * line_item.units
                                  ).toFixed(2)
                                : +line_item.percentage_vat < 1
                                ? (
                                    line_item.unit_price *
                                    line_item.units *
                                    (1 + line_item.percentage_vat)
                                  ).toFixed(2)
                                : (
                                    line_item.unit_price *
                                    line_item.units *
                                    (1 + line_item.percentage_vat / 100)
                                  ).toFixed(2)}
                            </span>
                          ),
                          data: 'string',
                          isPadded: true,
                        },
                        {
                          key: 'column7',
                          name: 'Enkel totaal',
                          fieldName: 'show_only_total',
                          minWidth: 80,
                          maxWidth: 80,
                          isRowHeader: true,
                          onRender: (line_item: any) => (
                            <span style={{ opacity: line_item.delete ? '0.5' : undefined }}>
                              <Checkbox
                                disabled
                                checked={line_item.show_only_total === true}
                              />
                            </span>
                          ),
                          data: 'string',
                          isPadded: true,
                        },
                        {
                          key: 'column8',
                          name: '',
                          fieldName: ' toggle delete',
                          minWidth: 80,
                          maxWidth: 80,
                          isRowHeader: true,
                          onRender: (item: ICustomerInvoiceLineItem, index) => (
                            <span>
                              <Icon
                                iconName={item.delete ? 'Refresh' : 'Delete'}
                                styles={iconProps}
                                onClick={() => {
                                  setCustomerInvoice((prevState) => {
                                    const newCustomerInvoice = _.cloneDeep(prevState);

                                    if (newCustomerInvoice && newCustomerInvoice.customer_invoice_line_items && index !== undefined) {
                                    newCustomerInvoice.customer_invoice_line_items[index].isDirty = true;
                                    newCustomerInvoice.customer_invoice_line_items[index].delete = !newCustomerInvoice.customer_invoice_line_items[index].delete;
                                    }

                                    return newCustomerInvoice;
                                  });
                                }}
                              />
                            </span>
                          ),
                          data: 'string',
                          isPadded: true,
                        },
                      ]}
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible
                selection={selectionInvoiceLine}
                selectionMode={SelectionMode.single}
                selectionPreservedOnEmptyClick
              />
              <Accordion
                selectedKey={selectedValueItem}
                defaultKey={undefined}
                toggleItem={(key: string | number) => {
                  if (selectedValueItem === key) {
                    setSelectedValueItem(undefined);
                  } else setSelectedValueItem(key);
                }}
              >
                {customerInvoice && (
                  <AccordionItem
                    key='accordion-invoice-contract-updates'
                    id='accordion-invoice-contract-updates'
                    title={
                      <Stack>
                        <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                          Min-/meerwerken
                        </h3>
                      </Stack>
                    }
                  >
                    {customerInvoice && !customerInvoice.sent && (
                      <>
                        <Stack>
                          <h4 style={{ marginTop: 0, marginBottom: 10 }}>
                            Beschikbaar
                          </h4>
                        </Stack>
                        <ShimmeredDetailsList
                          items={
                        customerContractUpdatesData && customerContractUpdatesData.findManyCustomerContractUpdates ? customerContractUpdatesData.findManyCustomerContractUpdates.filter((item: ICustomerContractUpdate) => {
                          if (item.customer_invoice) {
                            return false;
                          }
                          if (customerInvoice && customerInvoice.customer_contract_updates) {
                            for (let i = 0; i < customerInvoice.customer_contract_updates.length; i++) {
                              if (customerInvoice.customer_contract_updates[i].id === item.id) {
                                if (customerInvoice.customer_contract_updates[i].disconnect) {
                                  return true;
                                }
                                return false;
                              }
                            }
                          }

                          return true;
                        }) : []
                      }
                          columns={[
                        {
                          key: 'column1',
                          name: 'Bon nr',
                          fieldName: 'document_no',
                          minWidth: 75,
                          maxWidth: 100,
                          isRowHeader: true,
                          onRender: (item: ICustomerContractUpdate) => (
                            <span>{item.document_no}</span>
                          ),
                          data: 'string',
                          isPadded: true,
                        },
                        {
                          key: 'column2',
                          name: 'Datum',
                          fieldName: 'document_date',
                          minWidth: 100,
                          maxWidth: 200,
                          isRowHeader: true,
                          onRender: (item: ICustomerContractUpdate) => (
                            <span>{item.document_date ? moment(item.document_date).format('DD/MM/YYYY') : ''}</span>
                          ),
                          data: 'string',
                          isPadded: true,
                        },
                        {
                          key: 'column3',
                          name: 'Omschrijving',
                          fieldName: 'summary',
                          minWidth: 100,
                          // maxWidth: 100,
                          isRowHeader: true,
                          onRender: (item: ICustomerContractUpdate) => (
                            <span>{item.summary}</span>
                          ),
                          data: 'string',
                          isPadded: true,
                        },
                        {
                          key: 'column4',
                          name: '',
                          fieldName: 'addToInvoice',
                          minWidth: 100,
                          maxWidth: 100,
                          isRowHeader: true,
                          onRender: (item: ICustomerContractUpdate) => (
                            <button
                              type='button'
                              onClick={(e) => {
                                e.preventDefault();
                                addCustomerContractUpdate(item);
                            }}
                            >Voeg toe
                            </button>
                          ),
                          data: 'string',
                          isPadded: true,
                        },
                      ]}
                          layoutMode={DetailsListLayoutMode.justified}
                          isHeaderVisible
                          selectionMode={SelectionMode.none}
                        />
                      </>
                    )}

                    <Stack>
                      <h4 style={{ marginTop: 15, marginBottom: 10 }}>
                        Gelinkt aan deze factuur
                      </h4>
                    </Stack>
                    <ShimmeredDetailsList
                      items={
                        customerInvoice.customer_contract_updates ? customerInvoice.customer_contract_updates.filter(item => {
                          if (item.disconnect) {
                            return false;
                          }

                          return true;
                        }) : []
                      }
                      columns={[
                        {
                          key: 'column4',
                          name: 'Bon nr',
                          fieldName: 'document_no',
                          minWidth: 75,
                          maxWidth: 100,
                          isRowHeader: true,
                          onRender: (item: ICustomerContractUpdate) => (
                            <span>{item.document_no}</span>
                          ),
                          data: 'string',
                          isPadded: true,
                        },
                        {
                          key: 'column5',
                          name: 'Datum',
                          fieldName: 'document_date',
                          minWidth: 100,
                          maxWidth: 200,
                          isRowHeader: true,
                          onRender: (item: ICustomerContractUpdate) => (
                            <span>{item.document_date ? moment(item.document_date).format('DD/MM/YYYY') : ''}</span>
                          ),
                          data: 'string',
                          isPadded: true,
                        },
                        {
                          key: 'column6',
                          name: 'Omschrijving',
                          fieldName: 'description',
                          minWidth: 100,
                          // maxWidth: 100,
                          isRowHeader: true,
                          onRender: (item: ICustomerContractUpdate) => (
                            <span>{item.summary}</span>
                          ),
                          data: 'string',
                          isPadded: true,
                        },
                        {
                          key: 'column3',
                          name: '',
                          fieldName: 'addToInvoice',
                          minWidth: 100,
                          maxWidth: 100,
                          isRowHeader: true,
                          onRender: (item: ICustomerContractUpdate) => (
                            <span>
                              {customerInvoice && !customerInvoice.sent && (
                              <button
                                type='button'
                                onClick={(e) => {
                                e.preventDefault();
                                removeCustomerContractUpdate(item);
                              }}
                              >Verwijderen
                              </button>
                            )}
                            </span>
                          ),
                          data: 'string',
                          isPadded: true,
                        },
                      ]}
                      layoutMode={DetailsListLayoutMode.justified}
                      isHeaderVisible
                      selectionMode={SelectionMode.none}
                    />
                  </AccordionItem>
                )}
                {customerInvoice && (
                  <AccordionItem
                    key='accordion-invoice-payments'
                    id='accordion-invoice-payments'
                    title={
                      <Stack>
                        <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                          Betalingen
                        </h3>
                      </Stack>
                    }
                  >
                    <CommandBar
                      items={[
                        {
                          key: 'new',
                          text: 'Nieuw',
                          iconProps: { iconName: 'Add' },
                          onClick: () => openPaymentDetail(true),
                          theme: commandBarTheme,
                        },
                        {
                          key: 'modify',
                          text: 'Wijzig',
                          iconProps: { iconName: 'Edit' },
                          onClick: () => openPaymentDetail(),
                          theme: commandBarTheme,
                          disabled: !selectionPaymentDetails,
                        },
                      ]}
                      theme={commandBarTheme}
                    />
                    <ShimmeredDetailsList
                      items={customerInvoice.customer_invoice_payments || []}
                      columns={[
                        {
                          key: 'column1',
                          name: 'Datum',
                          fieldName: 'payment_date',
                          minWidth: 100,
                          maxWidth: 100,
                          isRowHeader: true,
                          onRender: (payment: any) => (
                            <span>
                              {moment(payment.payment_date).format(
                                'DD/MM/YYYY',
                              )}
                            </span>
                          ),
                          data: 'string',
                          isPadded: true,
                        },
                        {
                          key: 'column2',
                          name: 'Bedrag',
                          fieldName: 'amount',
                          minWidth: 100,
                          maxWidth: 100,
                          isRowHeader: true,
                          onRender: (payment: any) => (
                            <span>&euro; {payment.amount.toFixed(2)}</span>
                          ),
                          data: 'string',
                          isPadded: true,
                        },
                        {
                          key: 'column3',
                          name: 'Referentie / Nota',
                          fieldName: 'comments',
                          minWidth: 500,
                          maxWidth: 500,
                          isRowHeader: true,
                          onRender: (payment: any) => (
                            <span>{payment.comments}</span>
                          ),
                          data: 'string',
                          isPadded: true,
                        },
                      ]}
                      layoutMode={DetailsListLayoutMode.justified}
                      isHeaderVisible
                      selection={selectionPayment}
                      selectionMode={SelectionMode.single}
                      selectionPreservedOnEmptyClick
                    />
                  </AccordionItem>
                )}
              </Accordion>
            </Stack>
          ) : (
            <Stack style={{ marginTop: 30, marginBottom: 10 }}>
              <Label>
                Na opslaan kan je het detail van de factuur aanpassen.
              </Label>
              <Stack style={{ opacity: 0.3, pointerEvents: 'none' }}>
                <Accordion
                  selectedKey={undefined}
                  defaultKey={undefined}
                  toggleItem={() => {
                    setSelectedValueItem(undefined);
                  }}
                >
                  <AccordionItem
                    key='accordion-invoice-contract-updates'
                    id='accordion-invoice-contract-updates'
                    title={
                      <Stack>
                        <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                          Min-/meerwerken
                        </h3>
                      </Stack>
                    }
                  />
                  <AccordionItem
                    key='accordion-invoice-linte-items'
                    id='accordion-invoice-linte-items'
                    title={
                      <Stack>
                        <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                          Detail
                        </h3>
                      </Stack>
                    }
                  />
                  <AccordionItem
                    key='accordion-invoice-payments'
                    id='accordion-invoice-payments'
                    title={
                      <Stack>
                        <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                          Betalingen
                        </h3>
                      </Stack>
                    }
                  />
                </Accordion>
              </Stack>
            </Stack>
          )}
        </div>
        <div className={modalContentStyles.footer}>
          <Stack style={{ flexDirection: 'row', marginTop: 10 }}>
            <StackItem>
              <PrimaryButton
                onClick={saveCustomerInvoice}
                style={{ marginRight: 10 }}
                disabled={
                  !customerInvoice ||
                  (customerInvoice &&
                    !customerInvoice.invoice_date &&
                    !customerInvoice.construction_site)
                }
              >
                {customerInvoice && customerInvoice.id ? 'Opslaan' : 'Aanmaken'}
              </PrimaryButton>
            </StackItem>
            <StackItem>
              <DefaultButton onClick={dismissPanel}>Annuleren</DefaultButton>
            </StackItem>
          </Stack>
        </div>
      </div>
    </Panel>
  );
};

export default CustomerInvoiceDetail;
