import React, { useState } from 'react';
import {
  DefaultButton,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  Label,
  StackItem,
  TextField,
  IComboBoxOption,
} from '@fluentui/react';
import _ from 'lodash';
import { useMutation, useQuery } from '@apollo/client';
import {
  convertConstructionSitesToComboBoxOptions,
  displayNameCustomer,
  GET_CONSTRUCTION_SITES,
  IConstructionSite,
  modalContentStyles,
} from '../../../utils';
import {
  ADD_CUSTOMER_PAYMENT_TRANSACTION,
  GET_CUSTOMER_PAYMENT_TRANSACTION_BY_ID,
  ICustomerPaymentTransaction,
  UPDATE_CUSTOMER_PAYMENT_TRANSACTION,
} from '../../../utils/CustomerPaymentTransaction';
import { ComboboxWithFilter } from '../../../components/parts';
import DateView from '../../../components/parts/DateView';
import {
  GET_CONSTRUCTION_SITE_BY_ID_DETAILS,
  IConstructionSiteCreateUpdate,
} from '../../../utils/ConstructionSite';
import useDebounce from '../../../components/hooks/useDebounce';

type Props = {
  isOpen: boolean;
  dismissPanel: () => void;
  customerPaymentTransactionSource: ICustomerPaymentTransaction;
  constructionSite?: IConstructionSite | IConstructionSiteCreateUpdate;
  skipFetchConstructionSites?: boolean;
  paymentTransactionId?: number;
};

const PaymentTransactionDetail = ({
  isOpen,
  dismissPanel,
  customerPaymentTransactionSource,
  constructionSite,
  skipFetchConstructionSites,
  paymentTransactionId,
}: // constructionSites,
Props) => {
  const [customerPaymentTransaction, setCustomerPaymentTransaction] = useState(
    customerPaymentTransactionSource,
  );
  const { data } = useQuery(GET_CUSTOMER_PAYMENT_TRANSACTION_BY_ID, {
    variables: ((customerPaymentTransactionSource &&
      customerPaymentTransactionSource.id) ||
      paymentTransactionId) && {
      where: {
        id: customerPaymentTransactionSource.id || paymentTransactionId,
      },
    },
    skip:
      (!customerPaymentTransactionSource ||
        !customerPaymentTransactionSource.id) &&
      !paymentTransactionId,
    onCompleted: data => {
      setCustomerPaymentTransaction(data.findOneCustomerPaymentTransaction);
    },
  });

  // Save payment transaction
  const [addCustomerPaymentTransaction] = useMutation(
    ADD_CUSTOMER_PAYMENT_TRANSACTION,
    {
      refetchQueries: [
        {
          query: GET_CONSTRUCTION_SITE_BY_ID_DETAILS,
          variables: {
            where: {
              id:
                skipFetchConstructionSites &&
                constructionSite &&
                constructionSite.id
                  ? constructionSite.id
                  : customerPaymentTransaction.construction_site
                  ? customerPaymentTransaction.construction_site.id
                  : undefined,
            },
          },
        },
      ],
    },
  );
  const [modifyCustomerPaymentTransaction] = useMutation(
    UPDATE_CUSTOMER_PAYMENT_TRANSACTION,
    {
      refetchQueries: [
        {
          query: GET_CONSTRUCTION_SITE_BY_ID_DETAILS,
          variables: {
            where: {
              id:
                skipFetchConstructionSites &&
                constructionSite &&
                constructionSite.id
                  ? constructionSite.id
                  : customerPaymentTransaction.construction_site
                  ? customerPaymentTransaction.construction_site.id
                  : undefined,
            },
          },
        },
      ],
    },
  );

  const savePaymentTransaction = () => {
    if (customerPaymentTransaction) {
      const constructionSiteID =
        skipFetchConstructionSites && constructionSite && constructionSite.id
          ? constructionSite.id
          : customerPaymentTransaction.construction_site
          ? customerPaymentTransaction.construction_site.id
          : undefined;

      if (!constructionSiteID) return;
      const allInput = {
        description: customerPaymentTransaction.description,
        payment_date: customerPaymentTransaction.payment_date,
        percentage: customerPaymentTransaction.percentage,
        construction_site: {
          connect: {
            id: constructionSiteID,
          },
        },
        /* customer_invoice_legacy:
            customerPaymentTransaction.customer_invoice_legacy && {
              connect: {
                id: customerPaymentTransaction.customer_invoice_legacy.id,
              },
            }, */
      };

      if (customerPaymentTransaction.id) {
        modifyCustomerPaymentTransaction({
          variables: {
            id: customerPaymentTransaction.id,
            data: allInput,
          },
        }).then(() => {
          // refetchConstructionSite(); // => refetch constructionsite Payment Transactions!!
          dismissPanel();
        });
      } else {
        addCustomerPaymentTransaction({
          variables: {
            data: allInput,
          },
        }).then(() => {
          dismissPanel();
        });
      }
    }
  };

  const onChangeTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    if ((event.target as HTMLTextAreaElement).name === 'percentage') {
      setCustomerPaymentTransaction((prevState: any) => ({
        ...prevState,
        [(event.target as HTMLTextAreaElement).name]: newValue
          ? parseInt(newValue, 10)
          : 0,
      }));
    } else {
      setCustomerPaymentTransaction((prevState: any) => ({
        ...prevState,
        [(event.target as HTMLTextAreaElement).name]: newValue || '',
      }));
    }
  };

  const [constructionSiteFilter, setConstructionSiteFilter] = useState('');
  const debouncedFilter = useDebounce(constructionSiteFilter, 500);

  const { data: constructionSitesData } = useQuery(GET_CONSTRUCTION_SITES, {
    fetchPolicy: 'no-cache',
    variables: {
      take: 20,
      filter: {
        name: {
          contains: debouncedFilter,
        },
      },
    },
    skip: !!skipFetchConstructionSites,
  });

  const constructionSites =
    constructionSitesData && constructionSitesData.findManyConstructionSites;

  return (
    <Panel
      isLightDismiss
      isOpen={isOpen}
      onDismiss={dismissPanel}
      closeButtonAriaLabel='Close'
      headerText={
        customerPaymentTransaction && customerPaymentTransaction.id
          ? 'Betalingsschijf wijzigen'
          : 'Betalingsschijf toevoegen'
      }
      type={PanelType.custom}
      customWidth='500px'
    >
      <div>
        <div className={modalContentStyles.header} />
        <div className={modalContentStyles.body}>
          {customerPaymentTransaction && (
            <Label>ID: {customerPaymentTransaction.id}</Label>
          )}

          <Stack>
            <Stack style={{ marginBottom: 10 }}>
              <TextField
                name='description'
                label='Omschrijving'
                multiline
                value={
                  customerPaymentTransaction &&
                  customerPaymentTransaction.description
                    ? customerPaymentTransaction.description
                    : ''
                }
                onChange={onChangeTextFieldValue}
                required
              />
            </Stack>

            <Stack
              style={{
                marginBottom: 10,
              }}
            >
              {/* <ComboboxWithFilter
                label='Factuur'
                disabled
                options={convertCustomerInvoicesToComboBoxOptions(
                  customerInvoices,
                )}
                value={
                  paymentTransaction
                  && paymentTransaction.customer_invoice_legacy
                    ? paymentTransaction.customer_invoice_legacy.id
                    : ''
                }
                multiline={false}
                callBack={(newValue: IComboBoxOption[]) => {
                  if (newValue && newValue.length > 0) {
                    const result = { ...paymentTransaction };
                    if (result.customer_invoice_legacy) {
                      result.customer_invoice_legacy.id = newValue[0]
                        .key as number;
                    } else {
                      result.customer_invoice_legacy = {
                        id: newValue[0].key as number,
                      };
                    }
                    setPaymentTransaction(result);
                  }
                }}
              /> */}
              {customerPaymentTransaction.customer_invoices &&
                customerPaymentTransaction.customer_invoices.map(
                  item => item.invoice_no,
                )}
            </Stack>

            <Stack style={{ marginBottom: 10 }}>
              <DateView
                item={customerPaymentTransaction}
                setItem={setCustomerPaymentTransaction}
                date={
                  customerPaymentTransaction &&
                  customerPaymentTransaction.payment_date
                }
                label='Payment date'
                field='payment_date'
              />
            </Stack>

            <Stack
              style={{
                marginBottom: 10,
              }}
            >
              <ComboboxWithFilter
                label='Werf'
                options={convertConstructionSitesToComboBoxOptions(
                  skipFetchConstructionSites
                    ? [constructionSite]
                    : constructionSites || [],
                )}
                allowFreeForm
                value={
                  skipFetchConstructionSites &&
                  constructionSite &&
                  constructionSite.id
                    ? constructionSite.id
                    : customerPaymentTransaction &&
                      customerPaymentTransaction.construction_site
                    ? customerPaymentTransaction.construction_site.id
                    : ''
                }
                multiline={false}
                callBack={(newValue: IComboBoxOption[]) => {
                  if (newValue && newValue.length > 0) {
                    const result = { ...customerPaymentTransaction };
                    result.construction_site = {
                      id: newValue[0].key as number,
                    };
                    setCustomerPaymentTransaction(result);
                  }
                }}
                required
                disabled={skipFetchConstructionSites}
                setFilter={(value: string) => {
                  setConstructionSiteFilter(value);
                }}
              />
            </Stack>
            {customerPaymentTransaction &&
              !customerPaymentTransaction.construction_site &&
              customerPaymentTransaction.customer && (
                <Stack style={{ marginBottom: 10 }}>
                  Klant:{' '}
                  {displayNameCustomer(customerPaymentTransaction.customer)}
                </Stack>
              )}

            <Stack style={{ marginBottom: 10 }}>
              <TextField
                name='percentage'
                label='Percentage'
                type='number'
                value={
                  customerPaymentTransaction &&
                  customerPaymentTransaction.percentage
                    ? customerPaymentTransaction.percentage.toString()
                    : ''
                }
                onChange={onChangeTextFieldValue}
                required
              />
            </Stack>

            {customerPaymentTransaction.construction_site &&
              customerPaymentTransaction.construction_site.contract_amount &&
              customerPaymentTransaction.percentage && (
                <Stack>
                  Bedrag: &euro;{' '}
                  {customerPaymentTransaction.construction_site
                    .contract_amount *
                    (customerPaymentTransaction.percentage / 100)}
                </Stack>
              )}
          </Stack>
        </div>
        <div className={modalContentStyles.footer}>
          <Stack style={{ flexDirection: 'row', marginTop: 10 }}>
            <StackItem>
              <PrimaryButton
                onClick={savePaymentTransaction}
                style={{ marginRight: 10 }}
                disabled={
                  !customerPaymentTransaction ||
                  (customerPaymentTransaction &&
                    !customerPaymentTransaction.description &&
                    !customerPaymentTransaction.construction_site)
                }
              >
                Opslaan
              </PrimaryButton>
            </StackItem>
            <StackItem>
              <DefaultButton onClick={dismissPanel}>Annuleren</DefaultButton>
            </StackItem>
          </Stack>
        </div>
      </div>
    </Panel>
  );
};

export default PaymentTransactionDetail;
