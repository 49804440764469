import { gql } from '@apollo/client';
import { IComboBoxOption } from '@fluentui/react';
import { IConstructionSite, IConstructionSiteCreateUpdate } from './ConstructionSite';
import { ICustomer } from './Customer';
import { ICustomerContractUpdate } from './CustomerContractUpdate';
import { ICustomerInvoiceLineItem } from './CustomerInvoiceLineItem';
import { ICustomerInvoicePayment } from './CustomerInvoicePayment';
import { ICustomerPaymentTransaction } from './CustomerPaymentTransaction';
import { ILead, ILeadCreateUpdate } from './Lead';
import { IWorkOrder } from './WorkOrder';

export const GET_CUSTOMER_INVOICES = gql`
  query getCustomerInvoices(
    $filter: CustomerInvoiceFilterInput
    $take: Int
    $skip: Int
    $orderBy: CustomerInvoiceOrderByInput
  ) {
    findManyCustomerInvoices(
      filter: $filter
      take: $take
      skip: $skip
      orderBy: $orderBy
    ) {
      id
      credit_note
      customer_id
      intro
      invoice_date
      invoice_due_date
      invoice_no
      invoice_paid
      sent
      outro
      version
      customer {
        id
        first_name1
        first_name2
        last_name1
        last_name2
      }
      lead {
        id
        first_name1
        first_name2
        last_name1
        last_name2
        address1
        city1
        zip_code1
        phone1
        phone1_v2
        phone2
        phone2_v2
        mobile1
        mobile1_v2
        mobile2
        mobile2_v2
        email1
      }
      customer_invoice_line_items {
        comments
        customer_invoice_id
        description
        id
        percentage_vat
        show_only_total
        units
        unit_price
      }
      customer_invoice_payments {
        amount
        comments
        customer_invoice_id
        id
        payment_date
      }
      customer_payment_transaction {
        id
      }
      construction_site {
        name
        id

        lead {
          id
          first_name1
          first_name2
          last_name1
          last_name2
          address1
          city1
          zip_code1
          phone1
          phone1_v2
          phone2
          phone2_v2
          mobile1
          mobile1_v2
          mobile2
          mobile2_v2
        }
      }
    }
  }
`;

export const GET_CUSTOMER_INVOICE_BY_ID = gql`
  query getCustomerInvoiceById($where: CustomerInvoiceWhereInput) {
    findOneCustomerInvoice(where: $where) {
      id
      credit_note
      customer_id
      intro
      invoice_date
      invoice_due_date
      invoice_no
      invoice_paid
      sent
      outro
      version
      lead {
        id
        first_name1
        first_name2
        last_name1
        last_name2
        zip_code1
        address1
        city1
        phone1
        phone2
        mobile1
        mobile2
        email1
        email2
      }
      customer_invoice_line_items {
        id
        comments
        customer_invoice_id
        description
        id
        percentage_vat
        show_only_total
        units
        unit_price
        customer_contract_update_line_item {
          id
          description
          units
          unit_price
          percentage_vat
          show_only_total
        }
      }
      customer_invoice_payments {
        id
        amount
        comments
        customer_invoice_id
        id
        payment_date
      }
      construction_site {
        name
        id
        site_manager {
          id
          first_name
          last_name
          mobile
        }
      }
      customer_contract_updates {
        id
        document_no
        document_date
        summary
        customer_contract_update_line_items {
          id
          description
          units
          unit_price
          percentage_vat
          show_only_total
        }
      }
    }
  }
`;

export const ADD_CUSTOMER_INVOICE = gql`
  mutation AddCustomerInvoice($data: CustomerInvoiceCreateInput!) {
    createCustomerInvoice(data: $data) {
      id
      credit_note
      customer_id
      intro
      invoice_date
      invoice_due_date
      invoice_no
      invoice_paid
      outro
      version
      lead {
        id
        first_name1
        first_name2
        last_name1
        last_name2
      }
      customer_invoice_line_items {
        id
        comments
        customer_invoice_id
        description
        id
        percentage_vat
        show_only_total
        units
        unit_price
        customer_contract_update_line_item {
          id
          description
          units
          unit_price
          percentage_vat
          show_only_total
        }
      }
      customer_invoice_payments {
        id
        amount
        comments
        customer_invoice_id
        id
        payment_date
      }
      construction_site {
        name
        id
      }
      customer_contract_updates {
        id
        document_no
        document_date
        summary
      }
    }
  }
`;

export const UPDATE_CUSTOMER_INVOICE = gql`
  mutation UpdateCustomerInvoice(
    $id: Int!
    $data: CustomerInvoiceUpdateInput!
  ) {
    updateCustomerInvoice(id: $id, data: $data) {
      id
      credit_note
      customer_id
      intro
      invoice_date
      invoice_due_date
      invoice_no
      invoice_paid
      sent
      outro
      version
      lead {
        id
        first_name1
        first_name2
        last_name1
        last_name2
        zip_code1
        address1
        city1
        phone1
        phone2
        mobile1
        mobile2
        email1
        email2
      }
      customer_invoice_line_items {
        id
        comments
        customer_invoice_id
        description
        percentage_vat
        show_only_total
        units
        unit_price
        customer_contract_update_line_item {
          id
          description
          units
          unit_price
          percentage_vat
          show_only_total
        }
      }
      customer_invoice_payments {
        amount
        comments
        customer_invoice_id
        id
        payment_date
      }
      construction_site {
        name
        id
        site_manager {
          first_name
          last_name
          mobile
        }
      }
      customer_contract_updates {
        document_no
        document_date
        summary
      }
    }
  }
`;

export interface ICustomerInvoice {
  credit_note: boolean;
  customer_id: number;
  id: number;
  intro: string;
  invoice_date: Date;
  invoice_due_date: Date;
  invoice_no: string;
  invoice_paid: boolean;
  sent: boolean;
  outro: string;
  version: number;

  customer: ICustomer;
  customer_contract_updates: any[];
  customer_invoice_line_items: any[];
  customer_invoice_payments: any[];
  customer_payment_transactions_legacy: ICustomerPaymentTransaction[];
  customer_payment_transaction: ICustomerPaymentTransaction;
  defect_work_orders: any[];
  construction_site: IConstructionSite;
  lead: ILead;
}

export interface ICustomerInvoiceCreate {
  credit_note?: boolean;
  customer_id?: number;
  id?: number;
  intro?: string;
  invoice_date?: Date;
  invoice_due_date?: Date;
  invoice_no?: string;
  invoice_paid?: boolean;
  sent?: boolean;
  outro?: string;
  version?: number;

  customer?: ICustomer;
  customer_contract_updates?: ICustomerContractUpdate[];
  customer_invoice_line_items?: ICustomerInvoiceLineItem[];
  customer_invoice_payments?: ICustomerInvoicePayment[];
  customer_payment_transactions_legacy?: ICustomerPaymentTransaction[];
  customer_payment_transaction?: ICustomerPaymentTransaction;
  defect_work_orders?: IWorkOrder[];
  construction_site?: IConstructionSiteCreateUpdate;
  lead?: ILeadCreateUpdate;
}

export const convertCustomerInvoicesToComboBoxOptions = (
  customerInvoices: ICustomerInvoice[],
) => {
  if (customerInvoices) {
    const result: IComboBoxOption[] = customerInvoices.map(
      (customerInvoice: ICustomerInvoice): IComboBoxOption => ({
        key: customerInvoice.id,
        text: customerInvoice.invoice_no,
      }),
    );
    return result;
  }

  return [];
};
