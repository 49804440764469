import React from 'react';

import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import moment from 'moment';
import Logo from '../../../3Bouw_logo.png';

import fontSegoe from '../../../fonts/Segoe_UI.ttf';
import fontSegoeBold from '../../../fonts/Segoe_UI_Bold.ttf';
import {
  IConstructionSheetRemark,
  IConstructionSite,
  IProperty,
  property_types,
} from '../../../utils';
import { displayNameLead } from '../../../utils/Lead';
import theme from '../../../theme';

Font.register({
  family: 'Segoe UI',
  fonts: [{ src: fontSegoe }, { src: fontSegoeBold, fontStyle: 'bold' }],
});

const ExecutionListTemplate = ({
  constructionSite,
  title = 'Uitvoeringslijst',
  showSigningPage = false,
  isWaterPoints = false,
  waterPoints,
  remarks,
  date,
}: any) => (
  <Document>
    <Page size='A4' style={styles.page}>
      {/* Header repeated on every page */}
      <PageHeader constructionSite={constructionSite} title={title} />
      <View style={styles.pageHeader}>
        <View>
          <ConstructionSiteInfo constructionSite={constructionSite} />
        </View>
      </View>

      {/* ListContent */}
      {isWaterPoints ? (
        <ListContentWaterPoints waterPoints={waterPoints} />
      ) : (
        <ListContent constructionSite={constructionSite} />
      )}

      {/* Remarks */}
      {remarks &&
        remarks.length > 0 &&
        remarks.map((remark: IConstructionSheetRemark) => (
          <Remarks remarks={remark} />
        ))}

      {/* Footer repeated on every page */}
      <Text style={styles.now} fixed>
        {moment(date).format('DD/MM/YYYY')}
      </Text>
      <Text
        style={styles.pageNumber}
        render={({ pageNumber, totalPages }) =>
          `Pagina ${pageNumber} van ${totalPages}`
        }
        fixed
      />
    </Page>

    {showSigningPage && (
      <Page size='A4' style={styles.page}>
        {/* Header repeated on every page */}
        <PageHeader constructionSite={constructionSite} title={title} />

        {/* Date & sign area */}
        <SigningPage constructionSite={constructionSite} date={date} />

        {/* Footer repeated on every page */}
        <Text style={styles.now} fixed>
          {moment(new Date()).format('DD/MM/YYYY')}
        </Text>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `Pagina ${pageNumber} van ${totalPages}`
          }
          fixed
        />
      </Page>
    )}
  </Document>
);

const PageHeader = ({ constructionSite }: any) => (
  <View style={styles.pageHeaderWrapper} fixed>
    <View style={styles.pageHeader}>
      <Image src={Logo} style={styles.image} />
      <View>
        <Text style={styles.pageHeaderTitle}>Uitvoeringslijst</Text>
        <Text style={styles.pageHeaderName}>Werf: {constructionSite.name}</Text>
        {/* <Text style={styles.pageHeaderName}>
          Locatie: {constructionSite.city}
        </Text> */}
      </View>
    </View>
  </View>
);

const ConstructionSiteInfo = ({ constructionSite }: any) => (
  <View style={styles.constructionSiteAddress}>
    <View style={styles.constructionSiteInfoRow}>
      <View style={{ width: '45%' }}>
        <View style={{ flexDirection: 'row' }}>
          <Text style={{ width: '25%', fontStyle: 'bold' }}>Werfadres:</Text>
          <Text>
            {constructionSite.address}
            {'\n'}
            {constructionSite.zip_code} {constructionSite.city}
          </Text>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <Text style={{ width: '25%', fontStyle: 'bold' }}>Naam:</Text>
          <Text style={styles.constructionSiteInfoValue}>
            {displayNameLead(constructionSite.lead)}
          </Text>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <Text style={{ width: '25%', fontStyle: 'bold' }}>Tel.:</Text>
          <Text style={styles.constructionSiteInfoValue}>
            {constructionSite.lead.mobile1_v2 || constructionSite.lead.mobile1}
          </Text>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <Text style={{ width: '25%', fontStyle: 'bold' }}>E-mail:</Text>
          <Text style={styles.constructionSiteInfoValue}>
            {constructionSite.lead.email1}
          </Text>
        </View>
      </View>
      <View
        style={{
          width: '55%',
          borderLeft: '1px solid black',
          paddingLeft: 10,
        }}
      >
        {constructionSite.sales_rep && (
          <View style={{ flexDirection: 'row' }}>
            <Text style={{ width: '43%', fontStyle: 'bold' }}>Verkoper:</Text>
            <Text style={styles.constructionSiteInfoValue}>
              {constructionSite.sales_rep.first_name}{' '}
              {constructionSite.sales_rep.last_name}
            </Text>
          </View>
        )}
        {constructionSite.architect && (
          <View style={{ flexDirection: 'row' }}>
            <Text style={{ width: '43%', fontStyle: 'bold' }}>Architect:</Text>
            <Text style={styles.constructionSiteInfoValue}>
              {constructionSite.architect &&
                `${constructionSite.architect.first_name} ${constructionSite.architect.last_name}`}
            </Text>
          </View>
        )}
        {constructionSite.site_manager && (
          <View style={{ flexDirection: 'row' }}>
            <Text style={{ width: '43%', fontStyle: 'bold' }}>Werfleider:</Text>
            <Text style={styles.constructionSiteInfoValue}>
              {constructionSite.site_manager &&
                `${constructionSite.site_manager.first_name} ${constructionSite.site_manager.last_name}`}
            </Text>
          </View>
        )}
        {constructionSite.epb_reporter && (
          <View style={{ flexDirection: 'row' }}>
            <Text style={{ width: '43%', fontStyle: 'bold' }}>
              EPB verslaggever:
            </Text>
            <Text style={styles.constructionSiteInfoValue}>
              {constructionSite.epb_reporter &&
                `${constructionSite.epb_reporter.first_name} ${constructionSite.epb_reporter.last_name}`}
            </Text>
          </View>
        )}
        {constructionSite.safety_coordinator && (
          <View style={{ flexDirection: 'row' }}>
            <Text style={{ width: '43%', fontStyle: 'bold' }}>
              Veiligheidscoördinator:
            </Text>
            <Text style={styles.constructionSiteInfoValue}>
              {constructionSite.safety_coordinator &&
                `${constructionSite.safety_coordinator.first_name} ${constructionSite.safety_coordinator.last_name}`}
            </Text>
          </View>
        )}
      </View>
    </View>
  </View>
);

const ListContent = ({
  constructionSite,
}: {
  constructionSite: IConstructionSite;
}) => (
  <View style={styles.table}>
    {/* Table header repeated on every page */}
    <View style={styles.tableRowHeader} fixed>
      <Text style={styles.tableHeaderColLeft}>Omschrijving</Text>
      <Text style={styles.tableHeaderColRight}>Keuze &amp; Opmerkingen</Text>
    </View>

    {/* Table Rows */}
    {constructionSite.properties
      .sort((a, b) => a.weight_for_execution_list - b.weight_for_execution_list)
      .map((property: IProperty, key: number) => (
        <ListContentItem property={property} key={key} />
      ))}
  </View>
);

const ListContentItem = ({ property }: { property: IProperty }) => {
  if (property.is_not_applicable) {
    return (
      <View
        style={
          property.marked ? { backgroundColor: theme.palette.themeLight } : {}
        }
      >
        <ListContentItemTemplate label={property.name} value='n.v.t.' />
      </View>
    );
  }
  switch (property.type.name) {
    case property_types.DATE:
      return (
        <View
          style={
            property.marked ? { backgroundColor: theme.palette.themeLight } : {}
          }
        >
          <ListContentItemTemplate
            label={property.name}
            value={
              property.value
                ? moment(property.value).format('DD/MM/YYYY')
                : null
            }
          />
        </View>
      );
    case property_types.DOCUMENT:
      return (
        <View
          style={
            property.marked ? { backgroundColor: theme.palette.themeLight } : {}
          }
        >
          <ListContentItemTemplate
            label={property.name}
            value={property && property.document && property.document.name}
          />
        </View>
      );
    case property_types.MULTI_VALUE:
      return property.nested_type &&
        property.nested_type.name === property_types.PARENT ? (
        // eslint-disable-next-line react/jsx-indent
        <View
          style={
            property.marked ? { backgroundColor: theme.palette.themeLight } : {}
          }
        >
          {property.children.map(item => (
            <View style={styles.tableRow} wrap={false}>
              <ListContentItemMultiValueTemplate
                property={item}
                parent={property.name}
              />
            </View>
          ))}
        </View>
      ) : (
        <View
          style={[
            styles.tableRow,
            { backgroundColor: theme.palette.themeLight },
          ]}
          wrap={false}
        >
          <ListContentItemMultiValueTemplate property={property} />
        </View>
      );
    case property_types.SUGGESTED_VALUE:
      return (
        <View
          style={
            property.marked ? { backgroundColor: theme.palette.themeLight } : {}
          }
        >
          <ListContentItemTemplate
            label={property.name}
            value={
              property.suggested_value
                ? property.suggested_value.suggested_value
                : null
            }
          />
        </View>
      );
    default:
      return (
        <View
          style={
            property.marked ? { backgroundColor: theme.palette.themeLight } : {}
          }
        >
          <ListContentItemTemplate
            label={property.name}
            value={property.value}
          />
        </View>
      );
  }
};

const ListContentItemMultiValueTemplate = ({
  property,
  parent,
}: {
  property: IProperty;
  parent?: string;
}) => (
  <>
    <Text style={styles.tableColLeft}>
      {parent && (
        <Text>
          {parent}
          {': '}
        </Text>
      )}
      {property.name}
    </Text>
    <View style={styles.tableColRight}>
      {property.value ? (
        <Text style={{ marginBottom: '10px' }}>{property.value}</Text>
      ) : null}
      {property.children && property.children.length > 0
        ? property.children
            .sort(
              (a, b) =>
                a.weight_for_execution_list - b.weight_for_execution_list,
            )
            .map((child: IProperty, index: number) => {
              switch (child.type.name) {
                case property_types.DATE:
                  return (
                    <ListContentItemChildTemplate
                      label={child.name}
                      value={
                        child.value
                          ? moment(child.value).format('DD/MM/YYYY')
                          : null
                      }
                      position={index}
                    />
                  );
                case property_types.DOCUMENT:
                  return (
                    <ListContentItemChildTemplate
                      label={child.name}
                      value={child.document ? child.document.name : ''}
                      position={index}
                    />
                  );
                case property_types.SUGGESTED_VALUE:
                  return (
                    <ListContentItemChildTemplate
                      label={child.name}
                      value={
                        child.suggested_value
                          ? child.suggested_value.suggested_value
                          : null
                      }
                      position={index}
                    />
                  );
                default:
                  return (
                    <ListContentItemChildTemplate
                      label={child.name}
                      value={child.value}
                      position={index}
                    />
                  );
              }
            })
        : []}
    </View>
  </>
);

const ListContentItemTemplate = ({ label, value }: any) => (
  <View style={styles.tableRow} wrap={false}>
    <Text style={styles.tableColLeft}>{label}</Text>
    <Text style={styles.tableColRight}>{value}</Text>
  </View>
);

const ListContentItemChildTemplate = ({ label, value, position }: any) => (
  <Text style={position === 0 && !value ? {} : { paddingTop: '3px' }}>
    <Text style={{ fontStyle: 'bold' }}>
      {label.replace(':', '')}
      {label.indexOf('?') > -1 ? '' : ':'}
    </Text>{' '}
    {value}
  </Text>
);

const ListContentWaterPoints = ({
  waterPoints,
}: {
  waterPoints: IProperty[];
}) => (
  <View style={styles.table}>
    {/* Table header repeated on every page */}
    <View style={styles.tableRowHeader} fixed>
      <Text style={styles.tableHeaderColDescriptionWaterPoints}>
        Omschrijving
      </Text>
      <Text style={styles.tableHeaderColRainWaterPoints}>Regenwater</Text>
      <Text style={styles.tableHeaderColTapWaterPoints}>Leidingwater</Text>
      <Text style={styles.tableHeaderColTotalWaterPoints}>
        Totaal (Regenwater + Leidingwater)
      </Text>
    </View>

    {/* Table Rows */}
    {waterPoints.map((property: IProperty) => (
      <ListContentWaterPointsItem property={property} />
    ))}

    {/* Total Row */}
    <View
      style={[styles.tableRowWaterpoints, { borderTopWidth: 1 }]}
      wrap={false}
    >
      <Text
        style={[styles.tableColDescriptionWaterPoints, { fontStyle: 'bold' }]}
      >
        Totaal
      </Text>
      <Text style={styles.tableColRainWaterPoints}>
        {waterPoints
          .map((property: IProperty) => property.rain_water_points)
          .reduce(sumArray, 0)}
      </Text>
      <Text style={styles.tableColTapWaterPoints}>
        {waterPoints
          .map((property: IProperty) => property.tap_water_points)
          .reduce(sumArray, 0)}
      </Text>
      <Text style={styles.tableColTotalWaterPoints}>
        {waterPoints
          .map(
            (property: IProperty) =>
              property.rain_water_points + property.tap_water_points,
          )
          .reduce(sumArray, 0)}
      </Text>
    </View>

    {/* Toilet seat height */}
    <View style={[styles.tableRowWaterpoints, { paddingTop: '20px' }]}>
      <Text>Hoogte toilet:</Text>
      <Text style={{ paddingLeft: '135px' }}>cm</Text>
    </View>
  </View>
);

const ListContentWaterPointsItem = ({ property }: { property: IProperty }) => (
  <View style={styles.tableRowWaterpoints} wrap={false}>
    <Text style={styles.tableColDescriptionWaterPoints}>{property.name}</Text>
    <Text style={styles.tableColRainWaterPoints}>
      {property.rain_water_points}
    </Text>
    <Text style={styles.tableColTapWaterPoints}>
      {property.tap_water_points}
    </Text>
    <Text style={styles.tableColTotalWaterPoints}>
      {property.rain_water_points + property.tap_water_points}
    </Text>
  </View>
);

const Remarks = ({ remarks }: any) => (
  <View style={styles.remarks} wrap={false}>
    <Text style={styles.remarksLabel}>{remarks.label}</Text>
    <Text style={styles.remarksValue}>{remarks.remarks}</Text>
  </View>
);

const SigningPage = ({
  constructionSite,
  date,
}: {
  constructionSite: IConstructionSite;
  date: moment.Moment | string;
}) => (
  <View style={styles.signingArea}>
    <View style={styles.signingAreaCustomer}>
      <Text>{moment(date).format('DD/MM/YYYY')}</Text>
      <Text style={{ paddingTop: '5px' }}>Voor akkoord, de bouwheer</Text>
      <Text style={{ paddingTop: '15px', fontSize: 10 }}>
        {constructionSite.lead.first_name1} {constructionSite.lead.last_name1}
      </Text>
      <Text style={{ paddingTop: '5px', fontSize: 10 }}>
        {constructionSite.lead.first_name2} {constructionSite.lead.last_name2}
      </Text>
      <Text style={{ paddingTop: '5px', fontSize: 10 }}>
        {constructionSite.lead.address1}
      </Text>
      <Text style={{ paddingTop: '5px', fontSize: 10 }}>
        {constructionSite.lead.zip_code1} {constructionSite.lead.city1}
      </Text>
      <Text style={{ paddingTop: '5px', fontSize: 10 }}>
        {constructionSite.lead.phone1_v2
          ? constructionSite.lead.phone1_v2
          : constructionSite.lead.phone1
          ? constructionSite.lead.phone1
          : constructionSite.lead.mobile1_v2
          ? constructionSite.lead.mobile1_v2
          : constructionSite.lead.mobile1}
      </Text>
      <Text style={{ paddingTop: '15px' }}>Handtekening</Text>
    </View>

    <View style={styles.signingArea3bouw}>
      <Text>{moment(date).format('DD/MM/YYYY')}</Text>
      <Text style={{ paddingTop: '5px' }}>Voor akkoord</Text>
      <Text style={{ paddingTop: '15px', fontSize: 10 }}>3bouw bvba</Text>
      <Text style={{ paddingTop: '5px', fontSize: 10 }}>Zone Reme 1</Text>
      <Text style={{ paddingTop: '5px', fontSize: 10 }}>2260 Westerlo</Text>
      <Text style={{ paddingTop: '5px', fontSize: 10 }}>014/70 47 47</Text>
      <Text style={{ paddingTop: '5px', fontSize: 10 }}>&nbsp;</Text>
      <Text style={{ paddingTop: '15px' }}>Handtekening</Text>
    </View>
  </View>
);

const sumArray = (a: number, b: number) => a + b;

const styles = StyleSheet.create({
  constructionSiteInfoRow: {
    flexDirection: 'row',
    fontFamily: 'Segoe UI',
    fontSize: 10,
    paddingTop: '3px',
    lineHeight: '120%',
  },
  constructionSiteAddress: {},
  constructionSiteInfoValue: {},
  image: {
    width: '100px',
    // height: '35px',
    objectFit: 'scale-down',
    marginRight: 15,
  },
  now: {
    position: 'absolute',
    fontFamily: 'Segoe UI',
    fontSize: 10,
    bottom: 30,
    left: 50,
    right: 0,
    // color: 'grey',
  },
  page: {
    backgroundColor: '#ffffff',
    paddingBottom: 60,
    paddingLeft: 50,
    paddingRight: 50,
    paddingTop: 95,
  },
  pageHeaderWrapper: {
    position: 'absolute',
    top: 45,
    left: 45,
    right: 45,
  },
  pageHeader: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'row',
    paddingBottom: 10,
  },
  pageHeaderName: {
    fontFamily: 'Segoe UI',
    fontSize: 13,
  },
  pageHeaderTitle: {
    fontFamily: 'Segoe UI',
    fontSize: 15,
    fontStyle: 'bold',
  },
  pageNumber: {
    position: 'absolute',
    fontFamily: 'Segoe UI',
    fontSize: 10,
    bottom: 30,
    left: 0,
    right: 50,
    textAlign: 'right',
    // color: 'grey',
  },

  remarks: {
    fontFamily: 'Segoe UI',
    paddingTop: '20px',
  },
  remarksLabel: {
    fontSize: 11,
    fontStyle: 'bold',
    paddingBottom: '15px',
  },
  remarksValue: {
    fontSize: 10,
  },

  signingArea: {
    flexDirection: 'row',
    fontFamily: 'Segoe UI',
    fontSize: 12,
    marginTop: 20,
  },
  signingArea3bouw: {
    textAlign: 'right',
    width: '50%',
  },
  signingAreaCustomer: {
    textAlign: 'left',
    width: '50%',
  },
  table: {
    display: 'flex',
    width: 'auto',
    paddingTop: '15px',
  },
  tableColLeft: {
    fontFamily: 'Segoe UI',
    fontSize: 9,
    fontStyle: 'bold',
    paddingBottom: '5px',
    paddingLeft: '5px',
    paddingRight: '5px',
    paddingTop: '5px',
    width: '30%',
  },
  tableColRight: {
    fontFamily: 'Segoe UI',
    fontSize: 9,
    paddingBottom: '5px',
    paddingLeft: '5px',
    paddingRight: '5px',
    paddingTop: '5px',
    width: '70%',
  },
  tableColDescriptionWaterPoints: {
    width: '30%',
  },
  tableColRainWaterPoints: {
    paddingRight: '10px',
    textAlign: 'right',
    width: '15%',
  },
  tableColTapWaterPoints: {
    paddingRight: '10px',
    textAlign: 'right',
    width: '15%',
  },
  tableColTotalWaterPoints: {
    paddingRight: '10px',
    textAlign: 'right',
    width: '40%',
  },
  tableHeaderColLeft: {
    width: '30%',
    fontSize: 9,
  },
  tableHeaderColRight: {
    width: '70%',
    fontSize: 9,
  },
  tableHeaderColDescriptionWaterPoints: {
    width: '30%',
  },
  tableHeaderColRainWaterPoints: {
    width: '15%',
  },
  tableHeaderColTapWaterPoints: {
    width: '15%',
  },
  tableHeaderColTotalWaterPoints: {
    width: '40%',
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderColor: '#D2D4D1',
    fontSize: 10,
  },
  tableRowHeader: {
    backgroundColor: '#D2D4D1',
    flexDirection: 'row',
    fontFamily: 'Segoe UI',
    fontSize: 10,
    paddingBottom: '3px',
    paddingLeft: '5px',
    paddingTop: '5px',
  },
  tableRowWaterpoints: {
    flexDirection: 'row',
    fontFamily: 'Segoe UI',
    fontSize: 10,
    paddingBottom: '3px',
    paddingLeft: '5px',
    paddingRight: '5px',
    paddingTop: '5px',
  },

  textAlignRight: {
    textAlign: 'right',
  },
  textAlignCenter: {
    textAlign: 'center',
  },
});

export default ExecutionListTemplate;
