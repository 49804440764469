import React, { useEffect, useState } from 'react';
import {
  DefaultButton,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  StackItem,
  TextField,
  IComboBoxOption,
  Label,
  Checkbox,
  ShimmeredDetailsList,
  DetailsListLayoutMode,
  SelectionMode,
  Selection,
  IDetailsRowCheckStyles,
  IDetailsRowBaseProps,
  DetailsRowCheck,
  IDetailsFooterProps,
  DetailsRow,
} from '@fluentui/react';
import { useParams } from 'react-router';
import { Dropdown, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import moment from 'moment';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import _ from 'lodash';
import {
  convertArchitectsToComboBoxOptions,
  convertEmployeesToComboBoxOptions,
  GET_ARCHITECTS,
  GET_EMPLOYEES,
  IEmployee,
  modalContentStyles,
} from '../../../utils';
import {
  GET_LEADS,
  ILead,
  convertLeadsToComboBoxOptions,
} from '../../../utils/Lead';
import {
  ComboboxWithFilter,
  CommandBarSticky,
} from '../../../components/parts';
import SuggestedView from '../../../components/parts/SuggestedView';
import DateView from '../../../components/parts/DateView';
import { Accordion, AccordionItem } from '../../../components/parts/Accordion';
import { ICustomerPaymentTransaction } from '../../../utils/CustomerPaymentTransaction';
import { GET_TEMPLATES, ITemplate } from '../../../utils/Template';
import { commandBarTheme } from '../../../theme';
import PaymentTransactionDetail from '../../payment-transaction/components/CustomerPaymentTransactionDetail';
import useDebounce from '../../../components/hooks/useDebounce';
import CustomerContractUpdateDetail from '../../customer-contract-update/components/CustomerContractUpdateDetail';
import { ICustomerContractUpdate } from '../../../utils/CustomerContractUpdate';
import {
  ADD_CONSTRUCTION_SITE,
  GET_CONSTRUCTION_SITES,
  GET_CONSTRUCTION_SITE_BY_ID_DETAILS,
  IConstructionSite,
  IConstructionSiteCreateUpdate,
  UPDATE_CONSTRUCTION_SITE,
} from '../../../utils/ConstructionSite';
import {
  IInvoiceCustomer,
  DELETE_CONSTRUCTION_SITE_INVOICE_CUSTOMER,
} from '../../../utils/ConstructionSiteInvoiceCustomer';
import { useAppDispatch } from '../../../redux/hooks';
import {
  dismissNotification,
  sendNotification,
} from '../../../redux/notification/notificationSlice';
import { throwError, SeverityLevel } from '../../../redux/error/errorSlice';
import CustomerInvoiceDetail from './CustomerInvoiceDetail';

interface Props {
  isOpen: boolean;
  dismissPanel: () => void;
  // saveConstructionSite: () => void;
  constructionSiteSource: IConstructionSiteCreateUpdate;
  // setConstructionSite: React.Dispatch<React.SetStateAction<IConstructionSiteCreateUpdate | undefined>>;
  listFilters: any;
  constructionSiteId?: number;
}

const ConstructionSiteDetail = ({
  isOpen,
  dismissPanel,
  // saveConstructionSite,
  constructionSiteSource,
  // setConstructionSite,
  listFilters,
  constructionSiteId,
}: Props) => {
  const dispatch = useAppDispatch();
  const [selectedValueItem, setSelectedValueItem] = useState<
    number | string | undefined
  >();
  const [
    isCustomerPaymentTransactionPanelOpen,
    setIsCustomerPaymentTransactionPanelOpen,
  ] = useState(false);
  const [customerPaymentTransaction, setCustomerPaymentTransaction] =
    useState<ICustomerPaymentTransaction>();
  const [isInvoiceCustomerPanelOpen, setIsInvoiceCustomerPanelOpen] =
    useState(false);
  const [invoiceCustomer, setInvoiceCustomer] = useState<IInvoiceCustomer>();

  const [constructionSite, setConstructionSite] =
    useState<IConstructionSiteCreateUpdate>(constructionSiteSource);

  const [
    isCustomerContractUpdatePanelOpen,
    setIsCustomerContractUpdatePanelOpen,
  ] = useState(false);
  const [customerContractUpdate, setCustomerContractUpdate] =
    useState<ICustomerContractUpdate>();

  const [templates, setTemplates] = useState<ITemplate[]>([]);

  // Queries

  const { data: constructionSiteData } = useQuery(
    GET_CONSTRUCTION_SITE_BY_ID_DETAILS,
    {
      variables:
        (constructionSite && constructionSite.id) || constructionSiteId
          ? {
              where: { id: constructionSite.id || constructionSiteId },
              whereProps: {
                OR: [
                  { name: { contains: 'Gevelsteen' } },
                  { name: { contains: 'Voegwerk gevelmetselwerk' } },
                  { name: { contains: 'Type voordeur' } },
                  { name: { contains: 'Buitenschrijnwerk ramen' } },
                  { name: { contains: 'Dakpannen' } },
                ],
              },
            }
          : undefined,
      skip: (!constructionSite || !constructionSite.id) && !constructionSiteId,
    },
  );

  useEffect(() => {
    console.log(constructionSiteData);
  }, [constructionSiteData]);

  const [modifyConstructionSite] = useMutation(UPDATE_CONSTRUCTION_SITE, {
    onError: error => {
      dispatch(dismissNotification());

      dispatch(
        throwError({
          module: 'executionList.saveConstructionSite',
          message: error.message,
          level: SeverityLevel.Critical,
        }),
      );
    },
    // refetchQueries: [{ query: GET_CONSTRUCTION_SITES }],
    update(cache, { data }) {
      // We use an update function here to write the
      // new value of the GET_ALL_TODOS query.
      const updatedConstructionSite = data.updateConstructionSite;
      const existingConstructionSites = cache.readQuery<{
        findManyConstructionSites: IConstructionSite[];
      }>({
        query: GET_CONSTRUCTION_SITES,
        variables: listFilters,
      });

      const findManyConstructionSites = existingConstructionSites
        ? _.cloneDeep(existingConstructionSites?.findManyConstructionSites)
        : null;
      if (findManyConstructionSites && updatedConstructionSite) {
        for (let i = 0; i < findManyConstructionSites.length; i++) {
          if (findManyConstructionSites[i].id === updatedConstructionSite.id) {
            findManyConstructionSites[i] = updatedConstructionSite;
          }
        }
        cache.writeQuery({
          query: GET_CONSTRUCTION_SITES,
          variables: listFilters,
          data: {
            findManyConstructionSites,
          },
        });
      }
    },
  });
  const [addConstructionSite] = useMutation(ADD_CONSTRUCTION_SITE, {
    onError: error => {
      dispatch(dismissNotification());

      dispatch(
        throwError({
          module: 'executionList.saveConstructionSite',
          message: error.message,
          level: SeverityLevel.Critical,
        }),
      );
    },
    refetchQueries: [{ query: GET_CONSTRUCTION_SITES }],
  });

  const saveConstructionSite = () => {
    if (constructionSite) {
      if (constructionSite.name === '' || !constructionSite.name) return;

      if (
        constructionSite.zip_code &&
        Number.isNaN(+constructionSite.zip_code)
      ) {
        return;
      }

      if (
        !constructionSite.lead ||
        !constructionSite.lead.id ||
        constructionSite.lead.id === 0
      ) {
        return;
      }

      const data = {
        name: constructionSite.name,
        lead: {
          connect: {
            id: constructionSite.lead.id,
          },
        },
        address: constructionSite.address,
        comments: constructionSite.comments,
        city: constructionSite.city,
        contract_amount: constructionSite.contract_amount,
        zip_code: constructionSite.zip_code
          ? +constructionSite.zip_code
          : undefined,
        architect: constructionSite.architect
          ? {
              connect: { id: constructionSite.architect.id },
            }
          : undefined,
        site_manager: constructionSite.site_manager
          ? {
              connect: { id: constructionSite.site_manager.id },
            }
          : undefined,
        epb_reporter: constructionSite.epb_reporter
          ? {
              connect: { id: constructionSite.epb_reporter.id },
            }
          : undefined,
        project_manager: constructionSite.project_manager
          ? {
              connect: { id: constructionSite.project_manager.id },
            }
          : undefined,
        sales_rep: constructionSite.sales_rep
          ? {
              connect: { id: constructionSite.sales_rep.id },
            }
          : undefined,
        safety_coordinator: constructionSite.safety_coordinator
          ? {
              connect: { id: constructionSite.safety_coordinator.id },
            }
          : undefined,
        degree_of_completion: constructionSite.degree_of_completion,
        type_of_home: constructionSite.type_of_home,
        style_of_home: constructionSite.style_of_home,
        billboard_comments: constructionSite.billboard_comments,
        billboard_required: constructionSite.billboard_required,
        billboard_status: constructionSite.billboard_status,
        k_level: constructionSite.k_level,
        e_level: constructionSite.e_level,
        airtightness: constructionSite.airtightness,
        date_of_contract: constructionSite.date_of_contract,
        date_sold: constructionSite.date_sold,
        date_completed: constructionSite.date_completed,
        sounding_required: constructionSite.sounding_required,
        sounding_status: constructionSite.sounding_status,
        template_id:
          constructionSite.template_id === -1
            ? 0
            : constructionSite.template_id,
      };

      if (constructionSite.id) {
        dispatch(
          sendNotification({
            message: 'Bezig met opslaan.',
            level: 0,
            module: 'template.updateConstructionSite',
            spinner: true,
          }),
        );

        modifyConstructionSite({
          variables: {
            id: constructionSite.id,
            data,
          },
        }).then(() => {
          dispatch(dismissNotification());
          dismissPanel();
          // clearConstructionSites();
          // refetchConstructionSites();
        });
      } else {
        dispatch(
          sendNotification({
            message:
              'Bezig met opslaan. We maken op de achtergrond alle eigenschappen van de uitvoeringslijst & werffiche aan.',
            level: 0,
            module: 'template.addConstructionsite',
            spinner: true,
          }),
        );

        addConstructionSite({
          variables: {
            data,
          },
        }).then(x => {
          dispatch(dismissNotification());
          setConstructionSite(x.data.createConstructionSite);
          // selection.setItems(x.data.createConstructionSite);
        });
      }
    }
  };

  useEffect(() => {
    if (constructionSiteData && constructionSiteData.findOneConstructionSite) {
      setConstructionSite(prevState => {
        if (!prevState.updated) {
          return constructionSiteData.findOneConstructionSite;
        }

        return prevState;
      });
    }
  }, [constructionSiteData]);

  const [getTemplates] = useLazyQuery(GET_TEMPLATES, {
    onCompleted: data => {
      setTemplates(data.findManyTemplates);
    },
  });

  const [architectFilter, setArchitectFilter] = useState('');
  const debouncedArchitectFilter = useDebounce(architectFilter, 500);

  const getArchitectFilters = (filterstring: string) => {
    const filter: any = {
      AND: [],
    };
    if (filterstring) {
      const stringArray = filterstring.split(' ');
      for (let i = 0; i < stringArray.length; i++) {
        const filters: any = [
          { first_name: { contains: stringArray[i] } },
          { last_name: { contains: stringArray[i] } },
          { company: { contains: stringArray[i] } },
          { city: { contains: stringArray[i] } },
        ];

        const numericValue = parseInt(filter, 10);

        if (numericValue) {
          filters.OR.push({ zip_code: { equals: numericValue } });
        }

        filter.AND = [...filter.AND, { OR: filters }];
      }
    }
    return filter;
  };

  const { data: architectsData } = useQuery(GET_ARCHITECTS, {
    variables: {
      filter:
        debouncedArchitectFilter && debouncedArchitectFilter.length > 0
          ? getArchitectFilters(debouncedArchitectFilter)
          : {},
      take:
        debouncedArchitectFilter && debouncedArchitectFilter.length > 0
          ? undefined
          : 20,
    },
    fetchPolicy: 'no-cache',
  });

  const { data: employeesData } = useQuery(GET_EMPLOYEES, {
    fetchPolicy: 'no-cache',
  });

  const [leadFilter, setLeadFilter] = useState('');
  const debouncedLeadFilter = useDebounce(leadFilter, 500);

  const getLeadFilter = (filterstring: string) => {
    const filter: any = {
      AND: [],
    };
    if (filterstring) {
      const stringArray = filterstring.split(' ');
      for (let i = 0; i < stringArray.length; i++) {
        filter.AND = [
          ...filter.AND,
          {
            OR: [
              {
                first_name1: {
                  contains: stringArray[i],
                },
              },
              {
                first_name2: {
                  contains: stringArray[i],
                },
              },
              {
                last_name1: {
                  contains: stringArray[i],
                },
              },
              {
                last_name2: {
                  contains: stringArray[i],
                },
              },
            ],
          },
        ];
      }
    }

    return filter;
  };

  const [leads, setLeads] = useState<ILead[]>([]);
  useQuery(GET_LEADS, {
    variables: {
      filter:
        debouncedLeadFilter && debouncedLeadFilter.length > 0
          ? getLeadFilter(debouncedLeadFilter)
          : {},
      take:
        debouncedLeadFilter && debouncedLeadFilter.length > 0 ? undefined : 20,
    },
    fetchPolicy: 'no-cache',
    onCompleted: (x: any) => {
      const leads = x.findManyLeads;
      if (x.findManyLeads && constructionSite && constructionSite.lead && constructionSite.lead.id) {
        let currentLeadExists = false;
        for (let i = 0; i < x.findManyLeads.length; i++) {
          if (x.findManyLeads[i].id === constructionSite.lead.id) {
            currentLeadExists = true;
          }
          if (!currentLeadExists) {
            leads.push(constructionSite.lead);
          }
        }
      }
      setLeads(leads);
    },
  });

  // selection invoice customer
  /* const [
    selectionInvoiceCustomerDetails,
    setSelectionInvoiceCustomerDetails,
  ] = useState<IInvoiceCustomer| undefined>(); */

  const getSelectionInvoiceCustomerDetails = () => {
    const currentSelection: any = selectionInvoiceCustomer.getSelection();

    console.log(currentSelection);

    if (currentSelection.length > 0) {
      setInvoiceCustomer(currentSelection[0]);
    } else {
      setInvoiceCustomer({});
    }
  };

  const selectionInvoiceCustomer = new Selection({
    onSelectionChanged: getSelectionInvoiceCustomerDetails,
  });

  const openInvoiceCustomerDetail = (newIC?: boolean) => {
    if (newIC) {
      setInvoiceCustomer({});
    }
    setIsInvoiceCustomerPanelOpen(true);
  };

  const getSelectionCustomerContractUpdateDetails = () => {
    const currentSelection: any =
      selectionCustomerContractUpdate.getSelection();

    console.log(currentSelection);

    if (currentSelection.length > 0) {
      setCustomerContractUpdate(currentSelection[0]);
    } else {
      setCustomerContractUpdate({});
    }
  };

  const selectionCustomerContractUpdate = new Selection({
    onSelectionChanged: getSelectionCustomerContractUpdateDetails,
  });

  const openCustomerContractUpdateDetail = (newLead?: boolean) => {
    if (customerContractUpdate && !newLead) {
      // getCustomerContractUpdate({
      //   variables: {
      //     where: { id: selectionCustomerContractUpdateDetails.id },
      //   },
      // });
    } else {
      setCustomerContractUpdate(undefined);
    }
    setIsCustomerContractUpdatePanelOpen(true);
  };

  const [deleteInvoiceCustomer] = useMutation(
    DELETE_CONSTRUCTION_SITE_INVOICE_CUSTOMER,
  );
  // Selection payment transactions

  const getSelectionCustomerPaymentTransactionDetails = () => {
    const currentSelection: any =
      selectionCustomerPaymentTransaction.getSelection();

    if (currentSelection.length > 0) {
      setCustomerPaymentTransaction(currentSelection[0]);
    } else {
      setCustomerPaymentTransaction(undefined);
    }
  };

  const selectionCustomerPaymentTransaction = new Selection({
    onSelectionChanged: getSelectionCustomerPaymentTransactionDetails,
  });

  const openCustomerPaymentTransactionDetail = (newLead?: boolean) => {
    if (customerPaymentTransaction && !newLead) {
      // test
    } else {
      setCustomerPaymentTransaction(undefined);
    }
    setIsCustomerPaymentTransactionPanelOpen(true);
  };

  const onChangeTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    if (
      (event.target as HTMLTextAreaElement).name === 'contract_amount' &&
      newValue
    ) {
      setConstructionSite((prevState: any) => ({
        ...prevState,
        updated: true,
        [(event.target as HTMLTextAreaElement).name]:
          parseFloat(newValue) || '',
      }));
    } else {
      setConstructionSite((prevState: any) => ({
        ...prevState,
        updated: true,
        [(event.target as HTMLTextAreaElement).name]: newValue || '',
      }));
    }
  };

  const onChangeTemplate = (
    event: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption,
  ): void => {
    setConstructionSite((prevState: any) => ({
      ...prevState,
      updated: true,
      template_id: item ? item.key : -1,
    }));
  };

  useEffect(() => {
    if (
      !constructionSite ||
      !constructionSite.id ||
      constructionSite.id === -1
    ) {
      getTemplates();
    }
  }, []);

  const getTemplateOptions = (templates: ITemplate[]) => {
    const templateItems = templates.map(template => ({
      key: template.id!,
      text: template.name,
    }));
    templateItems.push({ key: -1, text: 'geen sjabloon' });

    return templateItems;
  };

  const renderDetailsFooterItemColumn: IDetailsRowBaseProps['onRenderItemColumn'] =
    (item, index, column) => {
      console.log(item);
      if (column) {
        return (
          <div>
            <b>{column.name}</b>
          </div>
        );
      }
      return undefined;
    };

  const detailsRowCheckStyles: Partial<IDetailsRowCheckStyles> = {
    root: { visibility: 'hidden' },
  };

  const onRenderCheckForFooterRow: IDetailsRowBaseProps['onRenderCheck'] = (
    props,
  ): JSX.Element => (
    <DetailsRowCheck {...props} styles={detailsRowCheckStyles} selected />
  );

  const onRenderDetailsFooter = (
    detailsFooterProps?: IDetailsFooterProps,
  ): JSX.Element => {
    if (!detailsFooterProps) return <>nothing</>;
    // console.log(detailsFooterProps);
    const item: ICustomerPaymentTransaction = {};
    if (constructionSite && constructionSite.payment_transactions) {
      let totalPercentage = 0;
      for (let i = 0; i < constructionSite.payment_transactions.length; i++) {
        totalPercentage +=
          constructionSite.payment_transactions[i].percentage || 0;
      }
      item.percentage = totalPercentage;
      item.description = 'Totaal';
    }
    return (
      <DetailsRow
        {...detailsFooterProps}
        columns={detailsFooterProps.columns}
        item={item}
        itemIndex={
          constructionSite.payment_transactions
            ? constructionSite.payment_transactions.length
            : 1
        }
        selectionMode={SelectionMode.single}
        onRenderCheck={onRenderCheckForFooterRow}
        styles={{
          root: {
            fontWeight: 'bold',
            backgroundColor: 'rgb(220 220 220)',
          },
        }}
      />
    );
  };

  return (
    <Panel
      isLightDismiss
      isOpen={isOpen}
      onDismiss={dismissPanel}
      closeButtonAriaLabel='Close'
      headerText={
        constructionSite && constructionSite.id
          ? 'Werf wijzigen'
          : 'Werf toevoegen'
      }
      type={PanelType.custom}
      customWidth='1200px'
      styles={{
        root: {
          zIndex: 8000,
        },
      }}
    >
      <div>
        <div className={modalContentStyles.header} />
        <div className={modalContentStyles.body}>
          {constructionSite && constructionSite.id && (
            <Label>ID: {constructionSite.id}</Label>
          )}

          <Stack
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <StackItem style={{ width: '48%' }}>
              {/* <h3 style={{ marginTop: 0, marginBottom: 10 }}>
                Algemene informatie
              </h3> */}
              <Stack>
                <Stack
                  style={{
                    marginBottom: 10,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <StackItem style={{ width: '100%' }}>
                    <TextField
                      name='name'
                      label='Naam'
                      value={
                        constructionSite && constructionSite.name
                          ? constructionSite.name
                          : ''
                      }
                      onChange={onChangeTextFieldValue}
                      required
                      errorMessage={
                        constructionSite && !constructionSite.name
                          ? 'Dit veld is verplicht'
                          : ''
                      }
                    />
                  </StackItem>
                </Stack>

                {/*
                <ComboBox
                  label='Sjabloon'
                  onSelect={(
                    keys: string | string[] | number | undefined,
                    value: string | undefined,
                  ) => {
                    if (keys && !Array.isArray(keys)) {
                      updateConstructionSiteNumberValue(keys, 'template');
                    }
                  }}
                  options={addEmptyOptionToTemplates()}
                  //allowFreeForm
                  selectedKey={getTemplateId()}
                />
                */}
                {(!constructionSite ||
                  !constructionSite.id ||
                  constructionSite.id === -1) && (
                  <Stack
                    style={{
                      marginBottom: 10,
                    }}
                  >
                    <Dropdown
                      label='Sjabloon'
                      selectedKey={
                        constructionSite ? constructionSite.template_id : -1
                      }
                      // eslint-disable-next-line react/jsx-no-bind
                      onChange={onChangeTemplate}
                      placeholder='Select an option'
                      options={getTemplateOptions(templates)}
                      // styles={dropdownStyles}
                    />
                  </Stack>
                )}

                <Stack
                  style={{
                    marginBottom: 10,
                  }}
                >
                  <ComboboxWithFilter
                    label='Klant'
                    options={leads ? convertLeadsToComboBoxOptions(leads) : []}
                    value={
                      constructionSite && constructionSite.lead
                        ? constructionSite.lead.id
                        : ''
                    }
                    multiline={false}
                    callBack={(newValue: IComboBoxOption[]) => {
                      if (newValue && newValue.length > 0) {
                        const result = { ...constructionSite };

                        result.lead = {
                          ...result.lead!,
                          id: newValue[0].key as number,
                        };

                        result.updated = true;

                        setConstructionSite(result);
                      }
                    }}
                    required
                    allowFreeForm
                    errorMessage={
                      constructionSite && !constructionSite.lead
                        ? 'Dit veld is verplicht'
                        : ''
                    }
                    setFilter={(value: string) => {
                      setLeadFilter(value);
                    }}
                  />
                </Stack>
                <Stack style={{ marginBottom: 5 }}>
                  <TextField
                    name='contract_amount'
                    label='Contractbedrag'
                    value={
                      constructionSite && constructionSite.contract_amount
                        ? constructionSite.contract_amount.toString()
                        : ''
                    }
                    prefix='€'
                    onChange={onChangeTextFieldValue}
                  />
                </Stack>
                <Stack style={{ marginBottom: 5 }}>
                  <TextField
                    name='address'
                    label='Adres'
                    value={
                      constructionSite && constructionSite.address
                        ? constructionSite.address
                        : ''
                    }
                    onChange={onChangeTextFieldValue}
                  />
                </Stack>

                <Stack
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 10,
                  }}
                >
                  <StackItem style={{ width: '25%' }}>
                    <TextField
                      name='zip_code'
                      placeholder='Postcode'
                      type='number'
                      value={
                        constructionSite && constructionSite.zip_code
                          ? constructionSite.zip_code.toString()
                          : ''
                      }
                      // required
                      /* errorMessage={
                        constructionSite && !constructionSite.zip_code
                          ? 'Dit veld is verplicht'
                          : ''
                      } */
                      onChange={onChangeTextFieldValue}
                    />
                  </StackItem>
                  <StackItem style={{ width: '74%' }}>
                    <TextField
                      name='city'
                      placeholder='Gemeente'
                      value={
                        constructionSite && constructionSite.city
                          ? constructionSite.city
                          : ''
                      }
                      onChange={onChangeTextFieldValue}
                    />
                  </StackItem>
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <TextField
                    label='Opmerkingen'
                    name='comments'
                    multiline
                    value={
                      constructionSite && constructionSite.comments
                        ? constructionSite.comments
                        : ''
                    }
                    onChange={onChangeTextFieldValue}
                  />
                </Stack>
              </Stack>

              <Stack
                style={{
                  marginBottom: 10,
                }}
              >
                <ComboboxWithFilter
                  label='Architect'
                  options={
                    architectsData && architectsData.findManyArchitects
                      ? convertArchitectsToComboBoxOptions(
                          architectsData.findManyArchitects,
                        )
                      : []
                  }
                  value={
                    constructionSite && constructionSite.architect
                      ? constructionSite.architect.id
                      : ''
                  }
                  allowFreeForm
                  multiline={false}
                  callBack={(newValue: IComboBoxOption[]) => {
                    if (newValue && newValue.length > 0) {
                      const result = { ...constructionSite };

                      result.architect = {
                        ...result.architect!,
                        id: newValue[0].key as number,
                      };

                      result.updated = true;

                      setConstructionSite(result);
                    }
                  }}
                  setFilter={(value: string) => {
                    setArchitectFilter(value);
                  }}
                />
              </Stack>

              <Stack
                style={{
                  marginBottom: 10,
                }}
              >
                <ComboboxWithFilter
                  label='Verkoper'
                  options={
                    employeesData && employeesData.findManyEmployees
                      ? convertEmployeesToComboBoxOptions(
                          [...employeesData.findManyEmployees].filter(
                            (employee: IEmployee) =>
                              employee.sales_rep === true,
                          ),
                        )
                      : []
                  }
                  value={
                    constructionSite && constructionSite.sales_rep
                      ? constructionSite.sales_rep.id
                      : ''
                  }
                  multiline={false}
                  callBack={(newValue: IComboBoxOption[]) => {
                    if (newValue && newValue.length > 0) {
                      const result = { ...constructionSite };

                      result.sales_rep = {
                        ...result.sales_rep,
                        id: newValue[0].key as number,
                      };

                      result.updated = true;

                      setConstructionSite(result);
                    }
                  }}
                />
              </Stack>

              <Stack
                style={{
                  marginBottom: 10,
                }}
              >
                <ComboboxWithFilter
                  label='EPB verslaggever'
                  options={
                    employeesData && employeesData.findManyEmployees
                      ? convertEmployeesToComboBoxOptions(
                          [...employeesData.findManyEmployees].filter(
                            (employee: IEmployee) =>
                              employee.epb_reporter === true,
                          ),
                        )
                      : []
                  }
                  value={
                    constructionSite && constructionSite.epb_reporter
                      ? constructionSite.epb_reporter.id
                      : ''
                  }
                  multiline={false}
                  callBack={(newValue: IComboBoxOption[]) => {
                    if (newValue && newValue.length > 0) {
                      const result = { ...constructionSite };

                      result.epb_reporter = {
                        ...result.epb_reporter,
                        id: newValue[0].key as number,
                      };

                      result.updated = true;

                      setConstructionSite(result);
                    }
                  }}
                />
              </Stack>

              <Stack
                style={{
                  marginBottom: 10,
                }}
              >
                <ComboboxWithFilter
                  label='Veiligheidscoördinator'
                  options={
                    employeesData && employeesData.findManyEmployees
                      ? convertEmployeesToComboBoxOptions(
                          [...employeesData.findManyEmployees].filter(
                            (employee: IEmployee) =>
                              employee.safety_coordinator === true,
                          ),
                        )
                      : []
                  }
                  value={
                    constructionSite && constructionSite.safety_coordinator
                      ? constructionSite.safety_coordinator.id
                      : ''
                  }
                  multiline={false}
                  callBack={(newValue: IComboBoxOption[]) => {
                    if (newValue && newValue.length > 0) {
                      const result = { ...constructionSite };

                      result.safety_coordinator = {
                        ...result.safety_coordinator,
                        id: newValue[0].key as number,
                      };

                      result.updated = true;

                      setConstructionSite(result);
                    }
                  }}
                />
              </Stack>

              <Stack
                style={{
                  marginBottom: 10,
                }}
              >
                <ComboboxWithFilter
                  label='Projectbeheerder'
                  options={
                    employeesData && employeesData.findManyEmployees
                      ? convertEmployeesToComboBoxOptions(
                          [...employeesData.findManyEmployees].filter(
                            (employee: IEmployee) =>
                              employee.customer_manager === true,
                          ),
                        )
                      : []
                  }
                  value={
                    constructionSite && constructionSite.project_manager
                      ? constructionSite.project_manager.id
                      : ''
                  }
                  multiline={false}
                  callBack={(newValue: IComboBoxOption[]) => {
                    if (newValue && newValue.length > 0) {
                      const result = { ...constructionSite };

                      result.project_manager = {
                        ...result.project_manager,
                        id: newValue[0].key as number,
                      };

                      result.updated = true;

                      setConstructionSite(result);
                    }
                  }}
                />
              </Stack>

              <Stack
                style={{
                  marginBottom: 10,
                }}
              >
                <ComboboxWithFilter
                  label='Werfleider'
                  options={
                    employeesData && employeesData.findManyEmployees
                      ? convertEmployeesToComboBoxOptions(
                          [...employeesData.findManyEmployees].filter(
                            (employee: IEmployee) =>
                              employee.construction_site_manager === true,
                          ),
                        )
                      : []
                  }
                  value={
                    constructionSite && constructionSite.site_manager
                      ? constructionSite.site_manager.id
                      : ''
                  }
                  multiline={false}
                  callBack={(newValue: IComboBoxOption[]) => {
                    if (newValue && newValue.length > 0) {
                      const result = { ...constructionSite };

                      result.site_manager = {
                        ...result.site_manager,
                        id: newValue[0].key as number,
                      };

                      result.updated = true;

                      setConstructionSite(result);
                    }
                  }}
                />
              </Stack>

              <Stack style={{ marginBottom: 10 }}>
                <DateView
                  item={constructionSite}
                  setItem={(item: IConstructionSiteCreateUpdate) => {
                    item.updated = true;
                    setConstructionSite(item);
                  }}
                  date={constructionSite.date_sold}
                  label='Datum verkoop'
                  field='date_sold'
                />
              </Stack>

              <Stack style={{ marginBottom: 10 }}>
                <DateView
                  item={constructionSite}
                  setItem={(item: IConstructionSiteCreateUpdate) => {
                    item.updated = true;
                    setConstructionSite(item);
                  }}
                  date={constructionSite.date_of_contract}
                  label='Datum contract'
                  field='date_of_contract'
                />
              </Stack>

              <Stack style={{ marginBottom: 10 }}>
                <DateView
                  item={constructionSite}
                  setItem={(item: IConstructionSiteCreateUpdate) => {
                    item.updated = true;
                    setConstructionSite(item);
                  }}
                  date={constructionSite && constructionSite.date_completed}
                  label='Datum oplevering'
                  field='date_completed'
                />
              </Stack>
            </StackItem>

            <StackItem style={{ width: '48%' }}>
              <Stack style={{ marginBottom: 10 }}>
                <SuggestedView
                  item={constructionSite}
                  setItem={(item: IConstructionSiteCreateUpdate) => {
                    item.updated = true;
                    setConstructionSite(item);
                  }}
                  prefix='billBoardRequired'
                  code={
                    constructionSite &&
                    constructionSite.billboard_required &&
                    constructionSite.billboard_required.substr(
                      constructionSite.billboard_required.length - 4,
                    )
                  }
                  label='Reclamebord toegestaan'
                  field='billboard_required'
                />
              </Stack>

              <Stack style={{ marginTop: 20, marginBottom: 10 }}>
                <Checkbox
                  name='billboard_status'
                  label='Reclamebord in orde'
                  checked={constructionSite.billboard_status || false}
                  onChange={(e, checked) => {
                    setConstructionSite((prevState: any) => ({
                      ...prevState,
                      updated: true,
                      billboard_status: checked,
                    }));
                  }}
                />
              </Stack>

              <Stack style={{ marginBottom: 10 }}>
                <TextField
                  name='billboard_comments'
                  label='Reclamebord info'
                  type='text'
                  value={
                    constructionSite && constructionSite.billboard_comments
                      ? constructionSite.billboard_comments
                      : ''
                  }
                  onChange={onChangeTextFieldValue}
                />
              </Stack>

              <Stack style={{ marginBottom: 10 }}>
                <TextField
                  name='k_level'
                  label='K-peil'
                  type='text'
                  value={
                    constructionSite && constructionSite.k_level
                      ? constructionSite.k_level
                      : ''
                  }
                  onChange={onChangeTextFieldValue}
                />
              </Stack>

              <Stack style={{ marginBottom: 10 }}>
                <TextField
                  name='e_level'
                  label='E-peil'
                  type='text'
                  value={
                    constructionSite && constructionSite.e_level
                      ? constructionSite.e_level
                      : ''
                  }
                  onChange={onChangeTextFieldValue}
                />
              </Stack>

              <Stack style={{ marginBottom: 10 }}>
                <TextField
                  name='airtightness'
                  label='Luchtdichtheidsmeting'
                  type='text'
                  value={
                    constructionSite && constructionSite.airtightness
                      ? constructionSite.airtightness
                      : ''
                  }
                  onChange={onChangeTextFieldValue}
                />
              </Stack>

              <Stack style={{ marginTop: 20, marginBottom: 10 }}>
                <Checkbox
                  name='sounding_required'
                  label='Sondering via 3bouw'
                  checked={constructionSite.sounding_required || false}
                  onChange={(e, checked) => {
                    setConstructionSite((prevState: any) => ({
                      ...prevState,
                      updated: true,
                      sounding_required: checked,
                    }));
                  }}
                />
              </Stack>

              <Stack style={{ marginTop: 20, marginBottom: 10 }}>
                <Checkbox
                  name='sounding_status'
                  label='Sondering in orde'
                  checked={constructionSite.sounding_status || false}
                  onChange={(e, checked) => {
                    setConstructionSite((prevState: any) => ({
                      ...prevState,
                      updated: true,
                      sounding_status: checked,
                    }));
                  }}
                />
              </Stack>

              <Stack style={{ marginBottom: 10 }}>
                <SuggestedView
                  item={constructionSite}
                  setItem={(item: IConstructionSiteCreateUpdate) => {
                    item.updated = true;
                    setConstructionSite(item);
                  }}
                  prefix='lead_degreeOfCompletion_'
                  code={
                    constructionSite &&
                    constructionSite.degree_of_completion &&
                    constructionSite.degree_of_completion.substr(
                      constructionSite.degree_of_completion.length - 4,
                    )
                  }
                  label='Graad van afwerking'
                  field='degree_of_completion'
                />
              </Stack>

              <Stack style={{ marginBottom: 10 }}>
                <SuggestedView
                  item={constructionSite}
                  setItem={(item: IConstructionSiteCreateUpdate) => {
                    item.updated = true;
                    setConstructionSite(item);
                  }}
                  prefix='lead_toh_'
                  code={
                    constructionSite &&
                    constructionSite.type_of_home &&
                    constructionSite.type_of_home.substr(
                      constructionSite.type_of_home.length - 4,
                    )
                  }
                  label='Type woning'
                  field='type_of_home'
                />
              </Stack>

              <Stack style={{ marginBottom: 10 }}>
                <SuggestedView
                  item={constructionSite}
                  setItem={(item: IConstructionSiteCreateUpdate) => {
                    item.updated = true;
                    setConstructionSite(item);
                  }}
                  prefix='customer_styleOfHome_'
                  code={
                    constructionSite &&
                    constructionSite.style_of_home &&
                    constructionSite.style_of_home.substr(
                      constructionSite.style_of_home.length - 4,
                    )
                  }
                  label='Stijl woning'
                  field='style_of_home'
                />
              </Stack>
              {/*
              <Stack>
                {constructionSite &&
                  constructionSite.properties &&
                  constructionSite.properties.map((property: any) => {
                    return (
                      <Stack style={{ marginTop: 15 }}>
                        <Label>{property.name}</Label>{' '}
                        <span>{property.value}</span>
                        {property.children &&
                          property.children.map((child: any) => {
                            return child.name === 'Type' ? (
                              <Stack style={{ marginTop: 3, marginLeft: 10 }}>
                                <Label>{child.name}</Label>{' '}
                                <span>{child.value}</span>
                              </Stack>
                            ) : null;
                          })}
                      </Stack>
                    );
                  })}
              </Stack>
                */}
            </StackItem>
          </Stack>

          <Stack style={{ marginBottom: 10, marginTop: 30 }}>
            {constructionSite && constructionSite.id ? (
              <Accordion
                selectedKey={selectedValueItem}
                defaultKey={undefined}
                toggleItem={(key: string | number) => {
                  if (selectedValueItem === key) {
                    setSelectedValueItem(undefined);
                  } else setSelectedValueItem(key);
                }}
              >
                <AccordionItem
                  key='accordion-contract-updates'
                  id='accordion-contract-updates'
                  title={
                    <Stack>
                      <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                        Min- en meerwerken
                      </h3>
                    </Stack>
                  }
                >
                  {isCustomerContractUpdatePanelOpen && (
                    <CustomerContractUpdateDetail
                      isOpen={isCustomerContractUpdatePanelOpen}
                      dismissPanel={() => {
                        setIsCustomerContractUpdatePanelOpen(false);
                        setCustomerContractUpdate(undefined);
                        selectionCustomerContractUpdate.setAllSelected(false);
                      }}
                      customerContractUpdateSource={
                        customerContractUpdate || {}
                      }
                      constructionSite={constructionSite}
                      skipFetchConstructionSites
                    />
                  )}
                  <CommandBarSticky
                    items={[
                      {
                        key: 'new',
                        text: 'Nieuw',
                        iconProps: { iconName: 'Add' },
                        onClick: () => openCustomerContractUpdateDetail(true),
                        theme: commandBarTheme,
                      },
                      {
                        key: 'modify',
                        text: 'Wijzig',
                        iconProps: { iconName: 'Edit' },
                        onClick: () => openCustomerContractUpdateDetail(),
                        theme: commandBarTheme,
                        disabled:
                          !customerContractUpdate || !customerContractUpdate.id,
                      },
                    ]}
                    theme={commandBarTheme}
                  />
                  <ShimmeredDetailsList
                    items={
                      constructionSiteData &&
                      constructionSiteData.findOneConstructionSite &&
                      constructionSiteData.findOneConstructionSite
                        .customer_contract_updates
                        ? constructionSiteData.findOneConstructionSite
                            .customer_contract_updates
                        : []
                    }
                    columns={[
                      {
                        key: 'column1',
                        name: 'Document nr',
                        fieldName: 'text',
                        minWidth: 300,
                        maxWidth: 300,
                        isRowHeader: true,
                        onRender: (customer_contract_update: any) => (
                          <span>
                            {customer_contract_update &&
                              customer_contract_update.document_no}
                          </span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column2',
                        name: 'Omschrijving',
                        fieldName: 'text',
                        minWidth: 300,
                        maxWidth: 300,
                        isRowHeader: true,
                        onRender: (customer_contract_update: any) => {
                          switch (customer_contract_update.status) {
                            case 'NEW':
                              return <span>Nieuw</span>;
                            case 'CANCELLED':
                              return <span>Geannuleerd</span>;
                            case 'INVOICED':
                              return <span>Op factuur</span>;
                            case 'COMPLETED':
                              return <span>Afgesloten</span>;
                            default:
                              break;
                          }
                        },
                        data: 'string',
                        isPadded: true,
                      },
                    ]}
                    layoutMode={DetailsListLayoutMode.justified}
                    isHeaderVisible
                    selection={selectionCustomerContractUpdate}
                    selectionMode={SelectionMode.single}
                    selectionPreservedOnEmptyClick
                  />
                </AccordionItem>
                <AccordionItem
                  key='accordion-log-items'
                  id='accordion-log-items'
                  title={
                    <Stack>
                      <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                        Betalingsschijven
                      </h3>
                    </Stack>
                  }
                >
                  {isCustomerPaymentTransactionPanelOpen && (
                    <PaymentTransactionDetail
                      isOpen={isCustomerPaymentTransactionPanelOpen}
                      dismissPanel={() => {
                        setIsCustomerPaymentTransactionPanelOpen(false);
                        setCustomerPaymentTransaction(undefined);
                      }}
                      customerPaymentTransactionSource={
                        customerPaymentTransaction || {}
                      }
                      constructionSite={constructionSite} // dummy
                      skipFetchConstructionSites
                    />
                  )}
                  <CommandBarSticky
                    items={[
                      {
                        key: 'new',
                        text: 'Nieuw',
                        iconProps: { iconName: 'Add' },
                        onClick: () =>
                          openCustomerPaymentTransactionDetail(true),
                        theme: commandBarTheme,
                      },
                      {
                        key: 'modify',
                        text: 'Wijzig',
                        iconProps: { iconName: 'Edit' },
                        onClick: () => openCustomerPaymentTransactionDetail(),
                        theme: commandBarTheme,
                        disabled:
                          !customerPaymentTransaction ||
                          !customerPaymentTransaction.id,
                      },
                    ]}
                    theme={commandBarTheme}
                  />
                  <ShimmeredDetailsList
                    items={
                      constructionSiteData &&
                      constructionSiteData.findOneConstructionSite &&
                      constructionSiteData.findOneConstructionSite
                        .payment_transactions
                        ? constructionSiteData.findOneConstructionSite
                            .payment_transactions
                        : []
                    }
                    columns={[
                      {
                        key: 'column1',
                        name: 'Omschrijving',
                        fieldName: 'description',
                        minWidth: 250,
                        maxWidth: 250,
                        isRowHeader: true,
                        onRender: (
                          customerPaymentTransaction: ICustomerPaymentTransaction,
                        ) => (
                          <span>{customerPaymentTransaction.description}</span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column2',
                        name: 'Factuur',
                        fieldName: 'customer_invoice',
                        minWidth: 110,
                        maxWidth: 110,
                        isRowHeader: true,
                        onRender: (
                          customerPaymentTransaction: ICustomerPaymentTransaction,
                        ) => (
                          <span>
                            {customerPaymentTransaction.customer_invoice_legacy &&
                            customerPaymentTransaction.customer_invoice_legacy
                              .invoice_no
                              ? `${customerPaymentTransaction.customer_invoice_legacy.invoice_no}`
                              : 'Geen factuur'}
                          </span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column3',
                        name: 'Factuurdatum',
                        fieldName: 'payment_date',
                        minWidth: 100,
                        maxWidth: 100,
                        isRowHeader: true,
                        onRender: (
                          customerPaymentTransaction: ICustomerPaymentTransaction,
                        ) => (
                          <span>
                            {customerPaymentTransaction.customer_invoice_legacy
                              ?.invoice_date &&
                              moment(
                                new Date(
                                  customerPaymentTransaction.customer_invoice_legacy.invoice_date,
                                ),
                              ).format('YYYY-MM-DD')}
                          </span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column3',
                        name: 'Percentage',
                        fieldName: 'percentage',
                        minWidth: 50,
                        maxWidth: 50,
                        isRowHeader: true,
                        onRender: (
                          payment_transaction: ICustomerPaymentTransaction,
                        ) => (
                          <span>
                            {payment_transaction.percentage &&
                              `${payment_transaction.percentage}%`}
                          </span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column4',
                        name: 'Bedrag',
                        fieldName: 'amount',
                        minWidth: 75,
                        maxWidth: 75,
                        isRowHeader: true,
                        onRender: (
                          payment_transaction: ICustomerPaymentTransaction,
                        ) => (
                          <span>
                            {payment_transaction.percentage &&
                              constructionSite.contract_amount &&
                              `€${(
                                (constructionSite.contract_amount *
                                  payment_transaction.percentage) /
                                100
                              ).toLocaleString('NL-nl')}`}
                          </span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column6',
                        name: 'Vervaldatum',
                        fieldName: 'contract_amount',
                        minWidth: 70,
                        maxWidth: 70,
                        isRowHeader: true,
                        onRender: (
                          customerPaymentTransaction: ICustomerPaymentTransaction,
                        ) => (
                          <span>
                            {customerPaymentTransaction.customer_invoice_legacy
                              ?.invoice_due_date &&
                              moment(
                                new Date(
                                  customerPaymentTransaction.customer_invoice_legacy.invoice_due_date,
                                ),
                              ).format('YYYY-MM-DD')}
                          </span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column7',
                        name: 'Betaald',
                        fieldName: 'payed',
                        minWidth: 50,
                        maxWidth: 50,
                        isRowHeader: true,
                        onRender: (
                          customerPaymentTransaction: ICustomerPaymentTransaction,
                        ) => (
                          <span>
                            <Checkbox
                              disabled
                              defaultChecked={
                                customerPaymentTransaction &&
                                customerPaymentTransaction.customer_invoice_legacy &&
                                customerPaymentTransaction
                                  .customer_invoice_legacy.invoice_paid
                              }
                            />
                          </span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                    ]}
                    layoutMode={DetailsListLayoutMode.justified}
                    isHeaderVisible
                    selection={selectionCustomerPaymentTransaction}
                    selectionMode={SelectionMode.single}
                    selectionPreservedOnEmptyClick
                    onRenderDetailsFooter={onRenderDetailsFooter}
                  />
                </AccordionItem>
                <AccordionItem
                  key='accordion-invoice-customer'
                  id='accordion-invoice-customer'
                  title={
                    <Stack>
                      <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                        Extra klanten voor facturatie
                      </h3>
                    </Stack>
                  }
                >
                  {invoiceCustomer && (
                    <CustomerInvoiceDetail
                      isOpen={isInvoiceCustomerPanelOpen}
                      dismissPanel={() => {
                        setIsInvoiceCustomerPanelOpen(false);
                        setInvoiceCustomer(undefined);
                      }}
                      invoiceCustomerSource={invoiceCustomer}
                      constructionSite={constructionSite}
                    />
                  )}

                  <CommandBarSticky
                    items={[
                      {
                        key: 'new',
                        text: 'Nieuw',
                        iconProps: { iconName: 'Add' },
                        onClick: () => openInvoiceCustomerDetail(true),
                        theme: commandBarTheme,
                      },
                      {
                        key: 'modify',
                        text: 'Wijzig',
                        iconProps: { iconName: 'Edit' },
                        onClick: () => openInvoiceCustomerDetail(),
                        theme: commandBarTheme,
                        disabled:
                          !invoiceCustomer ||
                          !invoiceCustomer.id ||
                          invoiceCustomer.id === -1,
                      },
                    ]}
                    theme={commandBarTheme}
                  />
                  <ShimmeredDetailsList
                    items={
                      constructionSiteData &&
                      constructionSiteData.findOneConstructionSite &&
                      constructionSiteData.findOneConstructionSite
                        .invoice_customers
                        ? constructionSiteData.findOneConstructionSite
                            .invoice_customers
                        : []
                    }
                    columns={[
                      {
                        key: 'column1',
                        name: 'Naam',
                        fieldName: 'text',
                        minWidth: 300,
                        maxWidth: 300,
                        isRowHeader: true,
                        onRender: (item: IInvoiceCustomer) => (
                          <span>
                            {item.lead && item.lead.first_name1}{' '}
                            {item.lead && item.lead.last_name1} -{' '}
                            {item.lead && item.lead.first_name2}{' '}
                            {item.lead && item.lead.last_name2}
                          </span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column2',
                        name: 'Percentage',
                        fieldName: 'text',
                        minWidth: 300,
                        maxWidth: 300,
                        isRowHeader: true,
                        onRender: (item: IInvoiceCustomer) => (
                          <span>{item.percentage}%</span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                    ]}
                    layoutMode={DetailsListLayoutMode.justified}
                    isHeaderVisible
                    selection={selectionInvoiceCustomer}
                    selectionMode={SelectionMode.single}
                    selectionPreservedOnEmptyClick
                    // enableShimmer={constructionSite.invoice_customers || constructionSite.invoice_customers.length === 0}
                  />
                  {(!constructionSite.invoice_customers ||
                    constructionSite.invoice_customers.length === 0) && (
                    <div style={{ textAlign: 'center' }}>
                      Geen resultaten gevonden
                    </div>
                  )}
                </AccordionItem>
              </Accordion>
            ) : (
              <>
                <Label>
                  Na opslaan kan je het detail van de min-/meerwerken en
                  betalingsschijven aanpassen.
                </Label>
                <Stack style={{ opacity: 0.3, pointerEvents: 'none' }}>
                  <Accordion
                    selectedKey={undefined}
                    defaultKey={undefined}
                    toggleItem={() => {
                      setSelectedValueItem(undefined);
                    }}
                  >
                    <AccordionItem
                      key='accordion-invoice-contract-updates'
                      id='accordion-invoice-contract-updates'
                      title={
                        <Stack>
                          <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                            Min-/meerwerken
                          </h3>
                        </Stack>
                      }
                    />
                    <AccordionItem
                      key='accordion-invoice-payments'
                      id='accordion-invoice-payments'
                      title={
                        <Stack>
                          <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                            Betalingschijven
                          </h3>
                        </Stack>
                      }
                    />
                    <AccordionItem
                      key='accordion-invoice-invoices'
                      id='accordion-invoice-invoices'
                      title={
                        <Stack>
                          <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                            Facturen
                          </h3>
                        </Stack>
                      }
                    />
                  </Accordion>
                </Stack>
              </>
            )}
          </Stack>
        </div>
        <div className={modalContentStyles.footer}>
          <Stack style={{ flexDirection: 'row', marginTop: 10 }}>
            <StackItem>
              <PrimaryButton
                onClick={saveConstructionSite}
                style={{ marginRight: 10 }}
                disabled={
                  !constructionSite ||
                  (constructionSite && !constructionSite.name)
                }
              >
                Opslaan
              </PrimaryButton>
            </StackItem>
            <StackItem>
              <DefaultButton onClick={dismissPanel}>Annuleren</DefaultButton>
            </StackItem>
          </Stack>
        </div>
      </div>
    </Panel>
  );
};

export default ConstructionSiteDetail;
