import { useState } from 'react';
import {
  DefaultButton,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  Label,
  StackItem,
  TextField,
  IComboBoxOption,
  Checkbox,
  DetailsListLayoutMode,
  Selection,
  SelectionMode,
  ShimmeredDetailsList,
} from '@fluentui/react';
import moment from 'moment';
import { useLazyQuery, useQuery, useMutation } from '@apollo/react-hooks';
import PhoneInput from 'react-phone-input-2';
import { useAuth0 } from '@auth0/auth0-react';
import {
  convertEmployeesToComboBoxOptions,
  IEmployee,
  modalContentStyles,
  titleComboboxOptions,
} from '../../../utils';
import SuggestedView from '../../../components/parts/SuggestedView';
import DateView from '../../../components/parts/DateView';
import {
  ComboboxWithFilter,
  CommandBarSticky,
} from '../../../components/parts';
import { GET_SUGGESTED_VALUE } from '../../../utils/SuggestedValue';
import { Accordion, AccordionItem } from '../../../components/parts/Accordion';
import { commandBarTheme } from '../../../theme';
import {
  ADD_QUOTE_REFERENCE,
  GET_QUOTE_REFERENCE,
  IQuoteReference,
  UPDATE_QUOTE_REFERENCE,
} from '../../../utils/QuoteReference';
import QuoteDetail from '../../quotes/components/QuoteDetail';
import LogItemDetail from '../../log-items/components/LogItemDetail';
import {
  ADD_LOG_ITEM,
  GET_LOG_ITEM_BY_ID,
  ILogItem,
  UPDATE_LOG_ITEM,
} from '../../../utils/LogItem';

const LeadLogItemTitle = (suggestedValue: any) => {
  const id = +suggestedValue.suggestedValue.substr(
    suggestedValue.suggestedValue.length - 4,
  );
  const { data } = useQuery(GET_SUGGESTED_VALUE, {
    variables: {
      where: {
        id,
      },
    },
  });

  if (data) {
    return <span>{data.findOneSuggestedValue.suggested_value}</span>;
  }
  return <span />;
};

const LeadDetail = ({
  isOpen,
  dismissPanel,
  saveLead,
  lead,
  setLead,
  employees,
  refetchLead,
}: any) => {
  const [selectedValueItem, setSelectedValueItem] = useState<
    number | string | undefined
  >();
  const [isQuotePanelOpen, setIsQuotePanelOpen] = useState(false);
  const [isLogItemPanelOpen, setIsLogItemPanelOpen] = useState(false);
  const [quote, setQuote] = useState<IQuoteReference>();
  const [logItem, setLogItem] = useState<ILogItem>();
  const { user } = useAuth0();
  const onChangeTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    setLead((prevState: any) => ({
      ...prevState,
      [(event.target as HTMLTextAreaElement).name]: newValue || '',
    }));
  };

  const [getQuoteReference] = useLazyQuery(GET_QUOTE_REFERENCE, {
    onCompleted: data => {
      setQuote(data.findOneQuoteReference);
    },
  });

  const [getLogItem] = useLazyQuery(GET_LOG_ITEM_BY_ID, {
    onCompleted: data => {
      setLogItem(data.findOneLogItem);
    },
  });

  // Selection quotes
  const [selectionDetails, setSelectionDetails] = useState<
    IQuoteReference | undefined
  >();

  const getSelectionDetails = () => {
    const currentSelection: any = selection.getSelection();

    if (currentSelection.length > 0) {
      setSelectionDetails(currentSelection[0]);
    } else {
      setSelectionDetails(undefined);
    }
  };

  const selection = new Selection({
    onSelectionChanged: getSelectionDetails,
  });

  const openQuoteDetail = (newLead?: boolean) => {
    if (selectionDetails && !newLead) {
      getQuoteReference({ variables: { where: { id: selectionDetails.id } } });
    } else {
      setQuote(undefined);
    }
    setIsQuotePanelOpen(true);
  };

  // Selection log items
  const [selectionLogItemDetails, setSelectionLogItemDetails] = useState<
    IQuoteReference | undefined
  >();

  const getSelectionLogItemDetails = () => {
    const currentSelection: any = selectionLogItem.getSelection();

    if (currentSelection.length > 0) {
      setSelectionLogItemDetails(currentSelection[0]);
    } else {
      setSelectionLogItemDetails(undefined);
    }
  };

  const selectionLogItem = new Selection({
    onSelectionChanged: getSelectionLogItemDetails,
  });

  const openLogItemDetail = (newLead?: boolean) => {
    if (selectionLogItemDetails && !newLead) {
      getLogItem({ variables: { where: { id: selectionLogItemDetails.id } } });
    } else {
      setLogItem(undefined);
    }
    setIsLogItemPanelOpen(true);
  };

  // Save quote
  const [addQuote] = useMutation(ADD_QUOTE_REFERENCE);
  const [modifyQuote] = useMutation(UPDATE_QUOTE_REFERENCE);

  const saveQuote = () => {
    if (quote) {
      const allInput = {
        quote_no: quote.quote_no,
        quote_date: quote.quote_date,
        description: quote.description,
        comments: quote.comments,
        lead: { connect: { id: lead.id } },
      };

      if (quote.id) {
        modifyQuote({
          variables: {
            id: quote.id,
            data: allInput,
          },
        }).then(() => {
          refetchLead();
          setIsQuotePanelOpen(false);
        });
      } else {
        addQuote({
          variables: {
            data: allInput,
          },
        }).then(() => {
          refetchLead();
          setIsQuotePanelOpen(false);
        });
      }
    }
  };

  // Save log item
  const [addLogItem] = useMutation(ADD_LOG_ITEM);
  const [modifyLogItem] = useMutation(UPDATE_LOG_ITEM);

  const saveLogItem = () => {
    if (logItem) {
      const allInput = {
        title: logItem.title,
        action_required: logItem.action_required,
        action_required_by: logItem.action_required_by,
        text: logItem.text,
        lead: { connect: { id: lead.id } },
      };

      if (logItem.id) {
        modifyLogItem({
          variables: {
            id: logItem.id,
            data: allInput,
          },
        }).then(() => {
          refetchLead();
          setIsLogItemPanelOpen(false);
        });
      } else {
        addLogItem({
          variables: {
            data: allInput,
          },
        }).then(() => {
          refetchLead();
          setIsLogItemPanelOpen(false);
        });
      }
    }
  };

  return (
    <Panel
      isLightDismiss
      isOpen={isOpen}
      onDismiss={dismissPanel}
      closeButtonAriaLabel='Close'
      headerText={lead && lead.id ? 'Prospect wijzigen' : 'Prospect toevoegen'}
      type={PanelType.custom}
      customWidth='900px'
    >
      <QuoteDetail
        isOpen={isQuotePanelOpen}
        dismissPanel={() => {
          setIsQuotePanelOpen(false);
        }}
        quote={quote}
        saveQuote={saveQuote}
        setQuote={setQuote}
        refetchLead={refetchLead}
      />
      <LogItemDetail
        isOpen={isLogItemPanelOpen}
        dismissPanel={() => {
          setIsLogItemPanelOpen(false);
        }}
        logItem={logItem}
        saveLogItem={saveLogItem}
        setLogItem={setLogItem}
        refetchLead={refetchLead}
      />

      <div>
        <div className={modalContentStyles.header} />
        <div className={modalContentStyles.body}>
          {/* {lead && ( */}
          {lead && <Label>ID: {lead.id}</Label>}

          <Stack
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <StackItem style={{ width: '48%' }}>
              <h3 style={{ marginTop: 0, marginBottom: 10 }}>Contact</h3>

              <Stack>
                <Stack style={{ marginBottom: 10 }}>
                  <ComboboxWithFilter
                    label='Aanspreking'
                    options={titleComboboxOptions()}
                    value={lead && lead.title1 ? lead.title1 : ''}
                    multiline={false}
                    callBack={(newValue: IComboBoxOption[]) => {
                      if (newValue && newValue.length > 0) {
                        const result = { ...lead };
                        result.isDirty = true;
                        result.title1 = newValue[0].key as number;
                        setLead(result);
                      }
                    }}
                  />
                </Stack>

                <Stack
                  style={{
                    marginBottom: 10,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <StackItem style={{ width: '49.5%' }}>
                    <TextField
                      name='first_name1'
                      label='Voornaam'
                      value={lead && lead.first_name1 ? lead.first_name1 : ''}
                      onChange={onChangeTextFieldValue}
                      required
                      errorMessage={
                        lead && !lead.first_name1 ? 'Dit veld is verplicht' : ''
                      }
                    />
                  </StackItem>
                  <StackItem style={{ width: '49.5%' }}>
                    <TextField
                      name='last_name1'
                      label='Achternaam'
                      value={lead && lead.last_name1 ? lead.last_name1 : ''}
                      onChange={onChangeTextFieldValue}
                      required
                      errorMessage={
                        lead && !lead.first_name1 ? 'Dit veld is verplicht' : ''
                      }
                    />
                  </StackItem>
                </Stack>

                <Stack style={{ marginBottom: 5 }}>
                  <TextField
                    name='address1'
                    label='Adres'
                    value={lead && lead.address1 ? lead.address1 : ''}
                    onChange={onChangeTextFieldValue}
                  />
                </Stack>

                <Stack
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 10,
                  }}
                >
                  <StackItem style={{ width: '25%' }}>
                    <TextField
                      name='zip_code1'
                      placeholder='Postcode'
                      type='number'
                      value={lead && lead.zip_code1 ? lead.zip_code1 : ''}
                      onChange={onChangeTextFieldValue}
                    />
                  </StackItem>
                  <StackItem style={{ width: '74%' }}>
                    <TextField
                      name='city1'
                      placeholder='Gemeente'
                      value={lead && lead.city1 ? lead.city1 : ''}
                      onChange={onChangeTextFieldValue}
                    />
                  </StackItem>
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <TextField
                    name='country'
                    label='Land'
                    value={lead && lead.country ? lead.country : ''}
                    onChange={onChangeTextFieldValue}
                  />
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <TextField
                    name='email1'
                    label='E-mailadres'
                    value={lead && lead.email1 ? lead.email1 : ''}
                    onChange={onChangeTextFieldValue}
                  />
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  {/* {lead.email1opt_out ? 'hoi' : 'no'} */}
                  <Checkbox
                    name='email1opt_out'
                    label='Geen mailing'
                    checked={lead ? lead.email1opt_out : false}
                    onChange={() => {
                      setLead((prevState: any) => ({
                        ...prevState,
                        email1opt_out: lead.email1opt_out
                          ? !lead.email1opt_out
                          : true,
                      }));
                    }}
                  />
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <Label>GSM</Label>
                  <PhoneInput
                    country='be'
                    preferredCountries={['be']}
                    value={lead && lead.mobile1_v2}
                    onChange={phone =>
                      setLead((prevState: any) => ({
                        ...prevState,
                        mobile1_v2: phone,
                      }))
                    }
                    inputStyle={{
                      width: '100%',
                      border: '1px solid rgb(96, 94, 92)',
                      borderRadius: '2px',
                    }}
                    buttonStyle={{
                      border: '1px solid rgb(96, 94, 92)',
                      borderRadius: '2px',
                    }}
                  />
                  {lead && !lead.mobile1_v2 && lead.mobile1 && (
                    <div>{lead.mobile1}</div>
                  )}
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <Label>Telefoon</Label>
                  <PhoneInput
                    country='be'
                    preferredCountries={['be']}
                    value={lead && lead.phone1_v2}
                    onChange={phone =>
                      setLead((prevState: any) => ({
                        ...prevState,
                        phone1_v2: phone,
                      }))
                    }
                    inputStyle={{
                      width: '100%',
                      border: '1px solid rgb(96, 94, 92)',
                      borderRadius: '2px',
                    }}
                    buttonStyle={{
                      border: '1px solid rgb(96, 94, 92)',
                      borderRadius: '2px',
                    }}
                  />
                  {lead && !lead.phone1_v2 && lead.phone1 && (
                    <div>{lead.phone1}</div>
                  )}
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <TextField
                    name='vat'
                    type='text'
                    label='BTW-nummer'
                    value={lead && lead.vat ? lead.vat : ''}
                    onChange={onChangeTextFieldValue}
                  />
                </Stack>
              </Stack>
            </StackItem>

            <StackItem style={{ width: '48%' }}>
              <h3 style={{ marginTop: 0, marginBottom: 10 }}>Partner</h3>
              <Stack>
                <Stack style={{ marginBottom: 10 }}>
                  <ComboboxWithFilter
                    label='Aanspreking'
                    options={titleComboboxOptions()}
                    value={lead && lead.title2 ? lead.title2 : ''}
                    multiline={false}
                    callBack={(newValue: IComboBoxOption[]) => {
                      if (newValue && newValue.length > 0) {
                        const result = { ...lead };
                        result.isDirty = true;
                        result.title2 = newValue[0].key as number;
                        setLead(result);
                      }
                    }}
                  />
                </Stack>

                <Stack
                  style={{
                    marginBottom: 10,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <StackItem style={{ width: '49.5%' }}>
                    <TextField
                      name='first_name2'
                      label='Voornaam'
                      value={lead && lead.first_name2 ? lead.first_name2 : ''}
                      onChange={onChangeTextFieldValue}
                    />
                  </StackItem>
                  <StackItem style={{ width: '49.5%' }}>
                    <TextField
                      name='last_name2'
                      label='Achternaam'
                      value={lead && lead.last_name2 ? lead.last_name2 : ''}
                      onChange={onChangeTextFieldValue}
                    />
                  </StackItem>
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <TextField
                    name='email2'
                    label='E-mailadres'
                    value={lead && lead.email2 ? lead.email2 : ''}
                    onChange={onChangeTextFieldValue}
                  />
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <Checkbox
                    name='email2opt_out'
                    label='Geen mailing'
                    checked={lead && lead.email2opt_out}
                    defaultChecked={lead && lead.email2opt_out}
                    onChange={() => {
                      setLead((prevState: any) => ({
                        ...prevState,
                        email2opt_out: lead.email2opt_out
                          ? !lead.email2opt_out
                          : true,
                      }));
                    }}
                  />
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <Label>GSM</Label>
                  <PhoneInput
                    country='be'
                    preferredCountries={['be']}
                    value={lead && lead.mobile2_v2}
                    onChange={phone =>
                      setLead((prevState: any) => ({
                        ...prevState,
                        mobile2_v2: phone,
                      }))
                    }
                    inputStyle={{
                      width: '100%',
                      border: '1px solid rgb(96, 94, 92)',
                      borderRadius: '2px',
                    }}
                    buttonStyle={{
                      border: '1px solid rgb(96, 94, 92)',
                      borderRadius: '2px',
                    }}
                  />
                  {lead && !lead.mobile2_v2 && lead.mobile2 && (
                    <div>{lead.mobile2}</div>
                  )}
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <Label>Telefoon</Label>
                  <PhoneInput
                    country='be'
                    preferredCountries={['be']}
                    value={lead && lead.phone2_v2}
                    onChange={phone =>
                      setLead((prevState: any) => ({
                        ...prevState,
                        phone2_v2: phone,
                      }))
                    }
                    inputStyle={{
                      width: '100%',
                      border: '1px solid rgb(96, 94, 92)',
                      borderRadius: '2px',
                    }}
                    buttonStyle={{
                      border: '1px solid rgb(96, 94, 92)',
                      borderRadius: '2px',
                    }}
                  />
                  {lead && !lead.phone2_v2 && lead.phone2 && (
                    <div>{lead.phone2}</div>
                  )}
                </Stack>
              </Stack>

              <Stack style={{ marginBottom: 10 }}>
                <TextField
                  name='wings_code'
                  label='Wings ID'
                  value={lead && lead.wings_code ? lead.wings_code : ''}
                  onChange={onChangeTextFieldValue}
                />
              </Stack>

              <Stack style={{ marginBottom: 10 }}>
                <TextField
                  name='wings_account_code'
                  label='Wings Analytische rekening ID'
                  value={
                    lead && lead.wings_account_code
                      ? lead.wings_account_code
                      : ''
                  }
                  onChange={onChangeTextFieldValue}
                />
              </Stack>

              <Stack>
                <h3 style={{ marginTop: 5, marginBottom: 10 }}>
                  Algemene opmerkingen
                </h3>
                <Stack>
                  <Stack style={{ marginBottom: 10 }}>
                    <TextField
                      name='comments'
                      multiline
                      value={lead && lead.comments ? lead.comments : ''}
                      onChange={onChangeTextFieldValue}
                    />
                  </Stack>
                </Stack>
              </Stack>
            </StackItem>
          </Stack>

          <Stack
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: 30,
            }}
          >
            <StackItem style={{ width: '48%' }}>
              <h3 style={{ marginTop: 0, marginBottom: 10 }}>Status</h3>
              <Stack>
                <Stack style={{ marginBottom: 10 }}>
                  <Label>Datum ingave</Label>
                  <p>
                    {lead &&
                      moment(new Date(lead.date_created ? lead.date_created : new Date())).format('DD/MM/YYYY')}
                  </p>
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <Label>Ingegeven door</Label>
                  <p>
                    {lead &&
                      lead.created_by ?
                      `${lead.created_by.first_name} ${lead.created_by.last_name}` : `${user?.given_name} ${user?.family_name}`}
                  </p>
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <Label>Datum laatste update</Label>
                  <p>
                    {lead &&
                      moment(new Date(lead.date_last_updated ? lead.date_last_updated : new Date())).format(
                        'DD/MM/YYYY',
                      )}
                  </p>
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <Label>Laatste update door</Label>
                  <p>
                    {lead &&
                      lead.last_updated_by ?
                      `${lead.last_updated_by.first_name} ${lead.last_updated_by.last_name}` : `${user?.given_name} ${user?.family_name}`}
                  </p>
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <ComboboxWithFilter
                    label='Status'
                    options={[
                      {
                        key: 'NIHIL',
                        text: 'Nihil',
                      },
                      { key: 'ACTIVE', text: 'Actief' },
                      { key: 'SOLD', text: 'Verkocht' },
                      { key: 'PASSIVE', text: 'Passief' },
                      {
                        key: 'PASSIVE_VISUAL_CONTACT',
                        text: 'Passief visueel contact',
                      },
                    ]}
                    value={lead && lead.status ? lead.status : ''}
                    multiline={false}
                    callBack={(newValue: IComboBoxOption[]) => {
                      if (newValue && newValue.length > 0) {
                        const result = { ...lead };
                        result.status = newValue[0].key;
                        setLead(result);
                      }
                    }}
                    required
                    errorMessage={
                      lead && !lead.status ? 'Dit veld is verplicht' : ''
                    }
                  />
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <TextField
                    name='reason_declined'
                    label='Reden passief / nihil'
                    multiline
                    value={
                      lead && lead.reason_declined ? lead.reason_declined : ''
                    }
                    onChange={onChangeTextFieldValue}
                  />
                </Stack>
              </Stack>
            </StackItem>

            <StackItem style={{ width: '48%' }}>
              <h3 style={{ marginTop: 0, marginBottom: 10 }}>Bouwgrond info</h3>
              <Stack>
                <Stack style={{ marginBottom: 10, marginTop: 10 }}>
                  <Checkbox
                    name='construction_site_available'
                    label='Bouwgrond beschikbaar?'
                    checked={lead && lead.construction_site_available}
                    defaultChecked={lead && lead.construction_site_available}
                    onChange={() => {
                      setLead((prevState: any) => ({
                        ...prevState,
                        construction_site_available:
                          lead.construction_site_available
                            ? !lead.construction_site_available
                            : true,
                      }));
                    }}
                  />
                </Stack>

                <Stack style={{ marginBottom: 5 }}>
                  <TextField
                    name='construction_site_address'
                    label='Adres (werf)'
                    placeholder='Straat + nummer'
                    value={
                      lead && lead.construction_site_address
                        ? lead.construction_site_address
                        : ''
                    }
                    onChange={onChangeTextFieldValue}
                  />
                </Stack>

                <Stack
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 10,
                  }}
                >
                  <StackItem style={{ width: '25%' }}>
                    <TextField
                      name='construction_site_zip_code'
                      placeholder='Postcode'
                      type='number'
                      value={
                        lead && lead.construction_site_zip_code
                          ? lead.construction_site_zip_code
                          : ''
                      }
                      onChange={onChangeTextFieldValue}
                    />
                  </StackItem>
                  <StackItem style={{ width: '74%' }}>
                    <TextField
                      name='construction_site_city'
                      placeholder='Gemeente'
                      value={
                        lead && lead.construction_site_city
                          ? lead.construction_site_city
                          : ''
                      }
                      onChange={onChangeTextFieldValue}
                    />
                  </StackItem>
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <TextField
                    name='construction_site_wanted_location'
                    label='Zoekt bouwgrond te'
                    value={
                      lead && lead.construction_site_wanted_location
                        ? lead.construction_site_wanted_location
                        : ''
                    }
                    onChange={onChangeTextFieldValue}
                  />
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <TextField
                    name='construction_site_comments'
                    label='Extra info (werf)'
                    multiline
                    value={
                      lead && lead.construction_site_comments
                        ? lead.construction_site_comments
                        : ''
                    }
                    onChange={onChangeTextFieldValue}
                  />
                </Stack>
              </Stack>
            </StackItem>
          </Stack>

          <Stack
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: 30,
            }}
          >
            <StackItem style={{ width: '48%' }}>
              <h3 style={{ marginTop: 0, marginBottom: 10 }}>
                Projectinformatie
              </h3>
              <Stack>
                <Stack
                  style={{
                    marginBottom: 10,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <StackItem style={{ width: '49.5%' }}>
                    <TextField
                      name='budget_estimate_low'
                      label='Budget (min)'
                      value={
                        lead && lead.budget_estimate_low
                          ? lead.budget_estimate_low
                          : ''
                      }
                      onChange={onChangeTextFieldValue}
                    />
                  </StackItem>
                  <StackItem style={{ width: '49.5%' }}>
                    <TextField
                      name='budget_estimate_high'
                      label='Budget (max)'
                      value={
                        lead && lead.budget_estimate_high
                          ? lead.budget_estimate_high
                          : ''
                      }
                      onChange={onChangeTextFieldValue}
                    />
                  </StackItem>
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <SuggestedView
                    item={lead}
                    setItem={setLead}
                    prefix='lead_degreeOfCompletion_'
                    code={
                      lead &&
                      lead.degree_of_completion &&
                      lead.degree_of_completion.substr(
                        lead.degree_of_completion.length - 4,
                      )
                    }
                    label='Graad van afwerking'
                    field='degree_of_completion'
                  />
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <SuggestedView
                    disabled
                    item={lead}
                    setItem={setLead}
                    prefix='lead_toh_'
                    code={
                      lead &&
                      lead.type_of_home &&
                      lead.type_of_home.substr(lead.type_of_home.length - 4)
                    }
                    label='Type woning'
                    field='type_of_home'
                  />
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <TextField
                    name='year_to_start_construction'
                    type='number'
                    label='Gepland bouwjaar'
                    value={
                      lead && lead.year_to_start_construction
                        ? lead.year_to_start_construction
                        : ''
                    }
                    onChange={onChangeTextFieldValue}
                  />
                </Stack>
              </Stack>
            </StackItem>

            <StackItem style={{ width: '48%' }}>
              <h3 style={{ marginTop: 0, marginBottom: 10 }}>Marketing</h3>
              <Stack>
                <Stack
                  style={{
                    marginBottom: 10,
                  }}
                >
                  <ComboboxWithFilter
                    label='Verkoper'
                    options={convertEmployeesToComboBoxOptions(
                      employees.filter((x: IEmployee) => x.sales_rep === true),
                    )}
                    value={
                      lead && lead.sales_rep_employee
                        ? lead.sales_rep_employee.id
                        : ''
                    }
                    multiline={false}
                    callBack={(newValue: IComboBoxOption[]) => {
                      if (newValue && newValue.length > 0) {
                        const result = { ...lead };
                        if (result.sales_rep_employee) {
                          result.sales_rep_employee.id = newValue[0]
                            .key as number;
                        } else {
                          result.sales_rep_employee = {
                            id: newValue[0].key as number,
                          };
                        }
                        setLead(result);
                      }
                    }}
                    required
                    errorMessage={
                      lead && !lead.sales_rep_employee
                        ? 'Dit veld is verplicht'
                        : ''
                    }
                  />
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <Checkbox
                    name='close_follow_up'
                    label='Speciale opvolging'
                    checked={lead && lead.close_follow_up}
                    defaultChecked={lead && lead.close_follow_up}
                    onChange={() => {
                      setLead((prevState: any) => ({
                        ...prevState,
                        close_follow_up: lead.close_follow_up
                          ? !lead.close_follow_up
                          : true,
                      }));
                    }}
                  />
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <SuggestedView
                    item={lead}
                    setItem={setLead}
                    prefix='lead_knownBy_'
                    code={
                      lead &&
                      lead.known_by &&
                      lead.known_by.substr(lead.known_by.length - 4)
                    }
                    label='Naambekendheid via'
                    field='known_by'
                  />
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <SuggestedView
                    item={lead}
                    setItem={setLead}
                    prefix='lead_contactMetod_'
                    code={
                      lead &&
                      lead.contact_method &&
                      lead.contact_method.substr(lead.contact_method.length - 4)
                    }
                    label='Contact opgenomen via'
                    field='contact_method'
                  />
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <DateView
                    item={lead}
                    setItem={setLead}
                    date={lead && lead.date_documentation_sent}
                    label='Datum documentatie verstuurd'
                    field='date_documentation_sent'
                  />
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <DateView
                    item={lead}
                    setItem={setLead}
                    date={lead && lead.date_visit_exhibition_house}
                    label='Datum laatste bezoek kijkwoning'
                    field='date_visit_exhibition_house'
                  />
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <DateView
                    item={lead}
                    setItem={setLead}
                    date={lead && lead.date_visit_exposition}
                    label='Datum laatste bezoek opendeur/werf'
                    field='date_visit_exposition'
                  />
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <DateView
                    item={lead}
                    setItem={setLead}
                    date={lead && lead.date_last_info_visit}
                    label='Datum laatste bezoek info-avond'
                    field='date_last_info_visit'
                  />
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <SuggestedView
                    item={lead}
                    setItem={setLead}
                    prefix='lead_competitors_'
                    code={
                      lead &&
                      lead.competitors &&
                      lead.competitors.substr(lead.competitors.length - 4)
                    }
                    label='Concurrentie'
                    field='competitors'
                  />
                </Stack>
              </Stack>
            </StackItem>
          </Stack>

          <Stack style={{ marginBottom: 10, marginTop: 30 }}>
            {lead && lead.id ? (
              <Accordion
                selectedKey={selectedValueItem}
                defaultKey={undefined}
                toggleItem={(key: string | number) => {
                  if (selectedValueItem === key) {
                    setSelectedValueItem(undefined);
                  } else setSelectedValueItem(key);
                }}
              >
                <AccordionItem
                  key='accordion-log-items'
                  id='accordion-log-items'
                  title={
                    <Stack>
                      <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                        Log items
                      </h3>
                    </Stack>
                  }
                >
                  <CommandBarSticky
                    items={[
                      {
                        key: 'new',
                        text: 'Nieuw',
                        iconProps: { iconName: 'Add' },
                        onClick: () => openLogItemDetail(true),
                        theme: commandBarTheme,
                      },
                      {
                        key: 'modify',
                        text: 'Wijzig',
                        iconProps: { iconName: 'Edit' },
                        onClick: () => openLogItemDetail(),
                        theme: commandBarTheme,
                        disabled: !selectionLogItemDetails,
                      },
                    ]}
                    theme={commandBarTheme}
                    width='1200px'
                    maxWidth='1200px'
                  />
                  <ShimmeredDetailsList
                    items={lead.log_items || []}
                    columns={[
                      {
                        key: 'column1',
                        name: 'Omschrijving',
                        fieldName: 'text',
                        minWidth: 150,
                        maxWidth: 150,
                        isRowHeader: true,
                        onRender: (log_item: any) => (
                          <span>
                            <LeadLogItemTitle suggestedValue={log_item.title} />
                          </span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column2',
                        name: 'Opvolging nodig',
                        fieldName: 'text',
                        minWidth: 100,
                        maxWidth: 100,
                        isRowHeader: true,
                        onRender: (log_item: any) => (
                          <span>
                            <Checkbox
                              checked={log_item.action_required}
                              disabled
                            />
                          </span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column3',
                        name: 'Verkoper/verantwoordelijke',
                        fieldName: 'text',
                        minWidth: 150,
                        maxWidth: 150,
                        isRowHeader: true,
                        onRender: (log_item: any) => (
                          <span>
                            {log_item &&
                              log_item.sales_rep &&
                              `${log_item.sales_rep.first_name} ${log_item.sales_rep.last_name}`}
                          </span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column4',
                        name: 'Opvolgen voor',
                        fieldName: 'text',
                        minWidth: 100,
                        maxWidth: 100,
                        isRowHeader: true,
                        onRender: (log_item: ILogItem) => (
                          <span>
                            {log_item.action_required_by && moment(new Date(log_item.action_required_by)).format(
                              'DD/MM/YYYY',
                            )}
                          </span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                    ]}
                    layoutMode={DetailsListLayoutMode.justified}
                    isHeaderVisible
                    selection={selectionLogItem}
                    selectionMode={SelectionMode.single}
                    selectionPreservedOnEmptyClick
                  />
                </AccordionItem>
                <AccordionItem
                  key='accordion-invoices'
                  id='accordion-invoices'
                  title={
                    <Stack>
                      <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                        Offertes
                      </h3>
                    </Stack>
                  }
                >
                  <CommandBarSticky
                    items={[
                      {
                        key: 'new',
                        text: 'Nieuw',
                        iconProps: { iconName: 'Add' },
                        onClick: () => openQuoteDetail(true),
                        theme: commandBarTheme,
                      },
                      {
                        key: 'modify',
                        text: 'Wijzig',
                        iconProps: { iconName: 'Edit' },
                        onClick: () => openQuoteDetail(),
                        theme: commandBarTheme,
                        disabled: !selectionDetails,
                      },
                    ]}
                    theme={commandBarTheme}
                    width='1200px'
                    maxWidth='1200px'
                  />
                  <ShimmeredDetailsList
                    items={lead.quote_references || []}
                    columns={[
                      {
                        key: 'column1',
                        name: 'Offerte nr',
                        fieldName: 'quote_no',
                        minWidth: 100,
                        maxWidth: 100,
                        isRowHeader: true,
                        onRender: (quote_reference: any) => (
                          <span>{quote_reference.quote_no}</span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column2',
                        name: 'Omschrijving',
                        fieldName: 'description',
                        minWidth: 300,
                        maxWidth: 300,
                        isRowHeader: true,
                        onRender: (quote_reference: any) => (
                          <span>{quote_reference.description}</span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column3',
                        name: 'Datum',
                        fieldName: 'quote_date',
                        minWidth: 100,
                        maxWidth: 100,
                        isRowHeader: true,
                        onRender: (quote_reference: any) => (
                          <span>
                            {moment(quote_reference.quote_date).format(
                              'DD/MM/YYYY',
                            )}
                          </span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                    ]}
                    layoutMode={DetailsListLayoutMode.justified}
                    isHeaderVisible
                    selection={selection}
                    selectionMode={SelectionMode.single}
                    selectionPreservedOnEmptyClick
                  />
                </AccordionItem>
              </Accordion>
            ) : (
              <>
                <Label>
                  Na opslaan kan je log items en offertes toevoegen en
                  aanpassen.
                </Label>
                <Stack style={{ opacity: 0.3, pointerEvents: 'none' }}>
                  <Accordion
                    selectedKey={undefined}
                    defaultKey={undefined}
                    toggleItem={(key: string | number) => {
                      setSelectedValueItem(undefined);
                    }}
                  >
                    <AccordionItem
                      key=''
                      id=''
                      title={
                        <Stack>
                          <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                            Log items
                          </h3>
                        </Stack>
                      }
                    />
                    <AccordionItem
                      key=''
                      id=''
                      title={
                        <Stack>
                          <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                            Offertes
                          </h3>
                        </Stack>
                      }
                    />
                  </Accordion>
                </Stack>
              </>
            )}
          </Stack>
          {/* )} */}
        </div>
        <div className={modalContentStyles.footer}>
          <Stack style={{ flexDirection: 'row', marginTop: 10 }}>
            <StackItem>
              <PrimaryButton
                onClick={saveLead}
                style={{ marginRight: 10 }}
                disabled={!lead || (lead && !lead.first_name1)}
              >
                Opslaan
              </PrimaryButton>
            </StackItem>
            <StackItem>
              <DefaultButton onClick={dismissPanel}>Annuleren</DefaultButton>
            </StackItem>
          </Stack>
        </div>
      </div>
    </Panel>
  );
};

export default LeadDetail;
