import React, { useState, useEffect } from 'react';
import {
  DetailsListLayoutMode,
  IColumn,
  ICommandBarItemProps,
  PrimaryButton,
  Selection,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
} from '@fluentui/react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  ADD_LEAD,
  displayNameLead,
  GET_LEAD_BY_ID,
  UPDATE_LEAD,
  ILead,
  ICreateLead,
} from '../../../utils/Lead';
import { commandBarTheme } from '../../../theme';
import { CommandBarSticky } from '../../../components/parts';
import { GET_EMPLOYEES } from '../../../utils';
import LeadDetail from './LeadDetail';

const LeadsOverview = ({
  leads,
  setLeads,
  loading,
  initialLoad,
  loadMore,
  setSorting,
  isSortedAsc = false,
  sortedField,
  showLoadMore,
  refetchLeads,
}: any) => {
  const [lead, setLead] = useState<ILead | ICreateLead | undefined>(undefined);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [employees, setEmployees] = useState([]);

  const [addLead] = useMutation(ADD_LEAD);
  const [modifyLead] = useMutation(UPDATE_LEAD);

  useQuery(GET_EMPLOYEES, {
    fetchPolicy: 'no-cache',
    onCompleted: (x: any) => {
      setEmployees(x.findManyEmployees);
    },
  });

  // Selection
  const [selectionDetails, setSelectionDetails] = useState<ILead | undefined>();

  const getSelectionDetails = () => {
    const currentSelection: any = selection.getSelection();

    if (currentSelection.length > 0) {
      setSelectionDetails(currentSelection[0]);
    } else {
      setSelectionDetails(undefined);
    }
  };

  const selection = new Selection({
    onSelectionChanged: getSelectionDetails,
  });

  const { refetch: refetchLead } = useQuery(GET_LEAD_BY_ID, {
    // notifyOnNetworkStatusChange: true,
    variables: selectionDetails
      ? { where: { id: selectionDetails.id } }
      : undefined,
    skip: !selectionDetails,
    onCompleted: data => {
      setLead(data.findOneLead);
    },
  });

  const openLeadDetail = (newLead?: boolean) => {
    if (selectionDetails && !newLead) {
      // getLead({ variables: { where: { id: selectionDetails.id } } });
    } else {
      setLead({ status: 'ACTIVE' });
    }
    setIsPanelOpen(true);
  };

  const saveLead = () => {
    if (lead) {
      const allInput = {
        address1: lead.address1,
        budget_estimate_high: lead.budget_estimate_high
          ? +lead.budget_estimate_high
          : null,
        budget_estimate_low: lead.budget_estimate_low
          ? +lead.budget_estimate_low
          : null,
        city1: lead.city1,
        close_follow_up: lead.close_follow_up,
        comments: lead.comments,
        competitors: lead.competitors,
        construction_site_address: lead.construction_site_address,
        construction_site_available: lead.construction_site_available,
        construction_site_city: lead.construction_site_city,
        construction_site_comments: lead.construction_site_comments,
        construction_site_wanted_location:
          lead.construction_site_wanted_location,
        construction_site_zip_code: lead.construction_site_zip_code
          ? +lead.construction_site_zip_code
          : null,
        contact_method: lead.contact_method,
        country: lead.country,
        date_documentation_sent: lead.date_documentation_sent,
        date_last_info_visit: lead.date_last_info_visit,
        date_visit_exhibition_house: lead.date_visit_exhibition_house,
        date_visit_exposition: lead.date_visit_exposition,
        degree_of_completion: lead.degree_of_completion,
        email1: lead.email1,
        email1opt_out: lead.email1opt_out,
        email2: lead.email2,
        email2opt_out: lead.email2opt_out,
        fax1: lead.fax1,
        fax2: lead.fax2,
        first_name1: lead.first_name1,
        first_name2: lead.first_name2,
        known_by: lead.known_by,
        last_name1: lead.last_name1,
        last_name2: lead.last_name2,
        mobile1_v2: lead.mobile1_v2,
        mobile2_v2: lead.mobile2_v2,
        phone1_v2: lead.phone1_v2,
        phone2_v2: lead.phone2_v2,
        reason_declined: lead.reason_declined,
        status: lead.status,
        title1: lead.title1,
        title2: lead.title2,
        type_of_home: lead.type_of_home,
        vat: lead.vat,
        year_to_start_construction: lead.year_to_start_construction
          ? +lead.year_to_start_construction
          : null,
        zip_code1: lead.zip_code1 ? +lead.zip_code1 : null,
        sales_rep_employee:
          lead.sales_rep_employee && lead.sales_rep_employee.id
            ? {
                connect: { id: lead.sales_rep_employee.id },
              }
            : undefined,
        wings_code: lead.wings_code ? +lead.wings_code : null,
        wings_account_code: lead.wings_account_code
          ? +lead.wings_account_code
          : null,
      };

      if (lead.id) {
        modifyLead({
          variables: {
            id: lead.id,
            data: allInput,
          },
        }).then(() => {
          setLeads((prevState: any) => ({
            ...prevState,
            leads: [],
          }));
          refetchLeads();
          setIsPanelOpen(false);
        });
      } else {
        addLead({
          variables: {
            data: allInput,
          },
        }).then(x => {
          setLead(x.data.createLead);
          selection.setItems(x.data.createLead);
        });
      }
    }
  };

  // Sorting
  const onColumnClick = (event: any, column: any) => {
    const sortedColumn = columns.filter((col: IColumn) => col.isSorted);
    let fieldName = '';
    let sortDescending = false;

    if (sortedColumn) {
      const newColumns = columns.map((col: IColumn) => {
        if (col.fieldName === column.fieldName) {
          col.isSorted = true;

          col.isSortedDescending =
            column.fieldName !== sortedColumn[0].fieldName
              ? false
              : !col.isSortedDescending;

          fieldName = column.fieldName;
          sortDescending = col.isSortedDescending;
        } else {
          col.isSorted = false;
        }
        return col;
      });
      setColumns(newColumns);

      setSorting(!sortDescending, fieldName);
    }
  };

  // Columns
  const columnsList = [
    {
      key: 'column1',
      name: 'Naam',
      fieldName: 'last_name1',
      minWidth: 250,
      maxWidth: 600,
      isRowHeader: true,
      onRender: (lead: ILead) => <span>{displayNameLead(lead)}</span>,
      onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column2',
      name: 'Adres',
      fieldName: 'address',
      minWidth: 230,
      maxWidth: 600,
      isRowHeader: true,
      onRender: (lead: ILead) => (
        <span>
          {lead.address1}
          <br />
          {lead.zip_code1} {lead.city1}
        </span>
      ),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column4',
      name: 'GSM',
      fieldName: 'mobile',
      minWidth: 120,
      maxWidth: 350,
      isRowHeader: true,
      onRender: (lead: ILead) => (
        <span>{lead.mobile1_v2 || lead.mobile2_v2}</span>
      ),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column5',
      name: 'Telefoon',
      fieldName: 'phone',
      minWidth: 120,
      maxWidth: 350,
      isRowHeader: true,
      onRender: (lead: ILead) => (
        <span>{lead.phone1_v2 || lead.phone2_v2}</span>
      ),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column6',
      name: 'Verkoper',
      fieldName: 'sales_rep_employee',
      minWidth: 120,
      maxWidth: 350,
      isRowHeader: true,
      onRender: (lead: ILead) => (
        <span>
          {lead.sales_rep_employee &&
            `${lead.sales_rep_employee.first_name} ${lead.sales_rep_employee.last_name}`}
        </span>
      ),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column7',
      name: 'Status',
      fieldName: 'status',
      minWidth: 100,
      maxWidth: 350,
      isRowHeader: true,
      onColumnClick,
      onRender: (lead: ILead) => {
        switch (lead.status) {
          case 'ACTIVE':
            return <span>Actief</span>;
          case 'SOLD':
            return <span>Verkocht</span>;
          case 'NIHIL':
            return <span>Nihil</span>;
          case 'PASSIVE':
            return <span>Passief</span>;
          case 'PASSIVE_VISUAL_CONTACT':
            return <span>Passief visueel contact</span>;
          default:
            return <span>{lead.status}</span>;
        }
      },
      data: 'string',
      isPadded: true,
    },
  ];

  const initColumns = (sortedField: string, isSortedAsc: boolean) =>
    columnsList.map((column: IColumn) => {
      if (column.fieldName === sortedField) {
        column.isSorted = true;
        column.isSortedDescending = !isSortedAsc;
        return column;
      }
      return column;
    });

  const [columns, setColumns] = useState(initColumns(sortedField, isSortedAsc));

  // Command bar
  const commandBaritems: ICommandBarItemProps[] = [
    {
      key: 'new',
      text: 'Nieuw',
      iconProps: { iconName: 'Add' },
      onClick: () => openLeadDetail(true),
      theme: commandBarTheme,
    },
    {
      key: 'modify',
      text: 'Wijzig',
      iconProps: { iconName: 'Edit' },
      onClick: () => openLeadDetail(),
      theme: commandBarTheme,
      disabled: !selectionDetails,
    },
  ];

  // Hooks
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        loadMore();
      }
    });
  });

  // Other
  const getKey = (item: any) => {
    if (item) return item.id;
    return null;
  };

  useEffect(() => {
    if (lead && isPanelOpen) {
      document.title = `3bouw | Prospect - ${displayNameLead(lead)}`;
    } else {
      document.title = '3bouw | Prospecten';
    }
  }, [lead, isPanelOpen]);

  return (
    <>
      <CommandBarSticky
        items={commandBaritems}
        theme={commandBarTheme}
        width='1200px'
        maxWidth='1200px'
      />

      <LeadDetail
        isOpen={isPanelOpen}
        dismissPanel={() => {
          setIsPanelOpen(false);
          setLead(undefined);
        }}
        lead={lead || {}}
        saveLead={saveLead}
        setLead={setLead}
        employees={employees}
        refetchLead={refetchLead}
      />

      {leads && (
        <>
          <ShimmeredDetailsList
            items={leads}
            columns={columns}
            getKey={getKey}
            enableShimmer={loading && initialLoad}
            ariaLabelForShimmer='Content is being fetched'
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible
            selection={selection}
            selectionMode={SelectionMode.single}
            selectionPreservedOnEmptyClick
          />
          {showLoadMore && (
            <Stack
              style={{
                marginTop: '15px',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
              horizontal
              horizontalAlign='center'
            >
              <PrimaryButton text='Toon meer' onClick={() => loadMore()} />
            </Stack>
          )}
          <Stack style={{ minHeight: '50px' }} />
        </>
      )}
      {!loading && !showLoadMore && leads.length === 0 && (
        <div
          style={{
            textAlign: 'center',
            fontWeight: 600,
            fontSize: 14,
          }}
        >
          Geen resultaten
        </div>
      )}
    </>
  );
};

export default LeadsOverview;
