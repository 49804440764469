import React, { useEffect, useState } from 'react';
import { Stack } from '@fluentui/react';

import { useAuth0 } from '@auth0/auth0-react';
import jwt_decode from 'jwt-decode';
import { returnFluentIcon } from '../../utils';
import Separator from './Separator';
import NavItem from './NavItem';

interface INavbarItem {
  key: string;
  icon: string;
  disableHover: boolean;
  isFavorite: boolean;
  href: string;
  text: string;
  isSeparator: boolean;
  permission?: string;
}

const NavbarItems: INavbarItem[] = [
  {
    key: '1',
    icon: 'Home',
    disableHover: true,
    isFavorite: false,
    href: '/start',
    text: 'Dashboard',
    isSeparator: false,
  },
  {
    key: '2',
    icon: 'StackIndicator',
    disableHover: false,
    isFavorite: false,
    href: '',
    text: 'Verkoop',
    isSeparator: true,
  },
  {
    key: '21',
    icon: 'People',
    disableHover: false,
    isFavorite: false,
    href: '/leads',
    text: 'Prospecten',
    isSeparator: false,
    permission: 'read:leads',
  },
  {
    key: '22',
    icon: 'People',
    disableHover: true,
    isFavorite: false,
    href: '/log-items',
    text: 'Log items',
    isSeparator: false,
    permission: 'read:logItems',
  },
  {
    key: '23',
    icon: 'People',
    disableHover: true,
    isFavorite: false,
    href: '/quotes',
    text: 'Offertes',
    isSeparator: false,
    permission: 'read:quoteReferences',
  },
  {
    key: '3',
    icon: 'StackIndicator',
    disableHover: false,
    isFavorite: false,
    href: '',
    text: 'Werfopvolging',
    isSeparator: true,
  },
  {
    key: '31',
    icon: 'People',
    disableHover: false,
    isFavorite: false,
    href: '/construction-sites',
    text: 'Werven',
    isSeparator: false,
    permission: 'read:constructionSites',
  },
  {
    key: '32',
    icon: 'People',
    disableHover: false,
    isFavorite: false,
    href: '/purchase-orders',
    text: 'Bestelbonnen',
    isSeparator: false,
    permission: 'read:purchaseOrders',
  },
  {
    key: '4',
    icon: 'StackIndicator',
    disableHover: false,
    isFavorite: false,
    href: '',
    text: 'Naverkoop',
    isSeparator: true,
  },
  {
    key: '41',
    icon: 'People',
    disableHover: true,
    isFavorite: false,
    href: '/work-orders',
    text: 'Werkbonnen',
    isSeparator: false,
    permission: 'read:defectWorkOrders',
  },
  {
    key: '5',
    icon: 'StackIndicator',
    disableHover: false,
    isFavorite: false,
    href: '',
    text: 'Boekhouding',
    isSeparator: true,
  },
  {
    key: '51',
    icon: 'People',
    disableHover: false,
    isFavorite: false,
    href: '/customers',
    text: 'Klanten',
    isSeparator: false,
    permission: 'read:customers',
  },
  {
    key: '52',
    icon: 'People',
    disableHover: true,
    isFavorite: false,
    href: '/payment-transactions',
    text: 'Betalingsschijven',
    isSeparator: false,
    permission: 'read:customerPaymentTransactions',
  },
  {
    key: '53',
    icon: 'People',
    disableHover: true,
    isFavorite: false,
    href: '/contract-updates',
    text: 'Min- en meerwerken',
    isSeparator: false,
    permission: 'read:customerContractUpdates',
  },
  {
    key: '54',
    icon: 'People',
    disableHover: true,
    isFavorite: false,
    href: '/customer-invoices',
    text: 'Uitgaande facturen',
    isSeparator: false,
    permission: 'read:customerInvoices',
  },
  {
    key: '55',
    icon: 'People',
    disableHover: false,
    isFavorite: false,
    href: '/suppliers',
    text: 'Leveranciers',
    isSeparator: false,
    permission: 'read:suppliers',
  },
  {
    key: '56',
    icon: 'People',
    disableHover: true,
    isFavorite: false,
    href: '/incoming-invoices',
    text: 'Inkomende facturen',
    isSeparator: false,
    permission: 'read:customerInvoices',
  },
  {
    key: '57',
    icon: 'People',
    disableHover: true,
    isFavorite: false,
    href: '/cost-centers',
    text: 'Boekhoudkundige posten',
    isSeparator: false,
    permission: 'read:costCenters',
  },
  {
    key: '6',
    icon: 'StackIndicator',
    disableHover: false,
    isFavorite: false,
    href: '',
    text: 'Organisatie',
    isSeparator: true,
  },
  {
    key: '61',
    icon: 'People',
    disableHover: true,
    isFavorite: false,
    href: '/employees',
    text: 'Medewerkers',
    isSeparator: false,
    permission: 'read:employees',
  },
  {
    key: '62',
    icon: 'People',
    disableHover: true,
    isFavorite: false,
    href: '/sales-reps',
    text: 'Verkopers',
    isSeparator: false,
    permission: 'read:employees',
  },
  {
    key: '63',
    icon: 'People',
    disableHover: true,
    isFavorite: false,
    href: '/new-timesheet',
    text: 'Werkuren registreren',
    isSeparator: false,
    permission: 'read:timeSheetEntries',
  },
  {
    key: '64',
    icon: 'People',
    disableHover: true,
    isFavorite: false,
    href: '/cars',
    text: 'Wagenpark',
    isSeparator: false,
    permission: 'read:cars',
  },
  {
    key: '65',
    icon: 'People',
    disableHover: true,
    isFavorite: false,
    href: '/architects',
    text: 'Architecten',
    isSeparator: false,
    permission: 'read:architects',
  },
  {
    key: '7',
    icon: 'StackIndicator',
    disableHover: false,
    isFavorite: false,
    href: '',
    text: 'Configuratie',
    isSeparator: true,
  },
  {
    key: '71',
    icon: 'People',
    disableHover: false,
    isFavorite: false,
    href: '/templates',
    text: 'Werf sjablonen',
    isSeparator: false,
    permission: 'read:templates',
  },
  {
    key: '72',
    icon: 'People',
    disableHover: false,
    isFavorite: false,
    href: '/purchase-order-templates',
    text: 'Bestelbon sjablonen',
    isSeparator: false,
    permission: 'read:purchaseOrders',
  },
  {
    key: '73',
    icon: 'People',
    disableHover: false,
    isFavorite: false,
    href: '/suggested-values',
    text: 'Voorgestelde waarden',
    isSeparator: false,
    permission: 'read:suggestedValueCategories',
  },
];

const NavbarItemsList = () => {
  const { getAccessTokenSilently } = useAuth0();

  const [permissions, setPermissions] = useState<string[]>([]);

  useEffect(() => {
    getAccessTokenSilently().then(token => {
      if (token) {
        const decoded: any = jwt_decode(token);
        if (decoded && typeof decoded === 'object' && decoded.permissions) {
          setPermissions(decoded.permissions);
        }
      }
    });
  }, []);

  return (
    <Stack>
      {NavbarItems.map((item: INavbarItem) => {
        if (item.isSeparator) {
          return (
            <Separator key={item.key} icon={returnFluentIcon(item.icon)}>
              {item.text}
            </Separator>
          );
        }
        if (permissions.includes(item.permission || '')) {
          return (
            <NavItem
              key={item.key}
              icon={returnFluentIcon(item.icon)}
              href={item.href}
              disableHover={item.disableHover}
              // isFavorite={item.isFavorite}
              navID='test'
            >
              {item.text}
            </NavItem>
          );
        }
        return null;
      })}
    </Stack>
  );
};

export default NavbarItemsList;
